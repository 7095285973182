import { ActiveLink } from 'components/ActiveLink';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone, icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import useUserInfo from 'hooks/use-user-info';

export default function SettingsLeftMenu() {
  const {
    userInfo: { stripeCustomerId },
  } = useUserInfo();

  return (
    <div>
      <div className="mb-6 flex flex-col">
        <ActiveLink to="/settings/account" className="inline-flex items-center rounded p-4">
          <FontAwesomeIcon
            icon={icon({ name: 'address-card', family: 'sharp', style: 'light' })}
            className="h-5 w-5 text-gray-600"
          />
          <span className="ml-4">Informations personnelles</span>
        </ActiveLink>
        <ActiveLink to="/settings/bank" className="inline-flex items-center rounded p-4">
          <FontAwesomeIcon
            icon={icon({ name: 'money-check-dollar-pen', family: 'sharp', style: 'light' })}
            className="h-5 w-5 text-gray-600"
          />
          <span className="ml-4">Données bancaires</span>
        </ActiveLink>
        <ActiveLink to="/settings/password" className="inline-flex items-center rounded p-4">
          <FontAwesomeIcon
            icon={icon({ name: 'fingerprint', family: 'sharp', style: 'light' })}
            className="h-5 w-5 text-gray-600"
          />
          <span className="ml-4">Mot de passe</span>
        </ActiveLink>
        {/*<ActiveLink*/}
        {/*  to="/settings/sacem"*/}
        {/*  className="p-4 rounded inline-flex items-center"*/}
        {/*>*/}
        {/*  <FontAwesomeIcon icon={icon({ name: 'music-note', family: 'sharp', style: 'light' })} className="h-5 w-5 text-sacem" />*/}
        {/*  <span className="ml-4">Données Sacem</span>*/}
        {/*</ActiveLink>*/}
        {stripeCustomerId && (
          <ActiveLink to="/settings/subscription" className="inline-flex items-center rounded p-4">
            <FontAwesomeIcon
              icon={icon({ name: 'wallet', family: 'sharp', style: 'light' })}
              className="h-5 w-5 text-blue"
            />
            <span className="ml-4">Abonnement & Factures</span>
          </ActiveLink>
        )}
        {/*<ActiveLink*/}
        {/*  to="/settings/notifications"*/}
        {/*  className="p-4 rounded inline-flex items-center">*/}
        {/*  <FontAwesomeIcon icon={icon({ name: 'alarm-clock', family: 'sharp', style: 'light' })} className="h-5 w-5 text-orange" />*/}
        {/*  <span className="ml-4">Gérer les alertes</span>*/}
        {/*</ActiveLink>*/}
        <ActiveLink to="/settings/offers" className="inline-flex items-center rounded p-4">
          <FontAwesomeIcon
            icon={icon({ name: 'gift-card', family: 'sharp', style: 'light' })}
            className="h-5 w-5 text-orange"
          />
          <span className="ml-4">Offres partenaires</span>
        </ActiveLink>
      </div>
    </div>
  );
}
