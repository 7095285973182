import React from 'react';
import PageTitle from '../layout/PageTitle';
import { CashOutHistoricPage } from './CashOutHistoricPage';

const CashOutHistoric = () => {
  return (
    <>
      <PageTitle title="Monitoring des médias" />
      <div className="w-full">
        <CashOutHistoricPage />
      </div>
    </>
  );
};

export default CashOutHistoric;
