import React from 'react';
import { Field, Form, FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import cx from 'classnames';
import InputField from 'components/InputField';
import { UpdateUserDTO, UserDTO } from 'shared-types';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import COADHint from 'components/COADHint';
import Alert from '../../components/Alert';
import TheModal from '../../components/TheModal';
import GenericError from 'components/GenericError';

const PersonnalDataFormSchema = Yup.object()
  .shape({
    firstName: Yup.string().required('Ce champ est obligatoire'),
    lastName: Yup.string().required('Ce champ est obligatoire'),
    phone: Yup.string(),
    addressLine1: Yup.string().required('Ce champ est obligatoire'),
    coad: Yup.string().required('Ce champ est obligatoire'),
  })
  .defined();

export type PersonnalDataFormValues = Yup.InferType<typeof PersonnalDataFormSchema>;

type Props = {
  initialData: Partial<UserDTO>;
  handleSubmit: (values: Partial<UpdateUserDTO>) => void;
  updateSuccess?: boolean;
  updateError?: boolean;
  closeUpdateSuccessMessage: () => void;
};

class PersonnalDataForm extends React.Component<Props & FormikProps<PersonnalDataFormValues>> {
  render() {
    const { updateSuccess, updateError, isSubmitting, closeUpdateSuccessMessage } = this.props;
    return (
      <Form>
        {updateSuccess && (
          <div className="message mb-6 flex justify-between bg-green-500 text-white">
            <div className="content">Informations mises à jour.</div>
            <div className="cursor-pointer self-baseline" onClick={closeUpdateSuccessMessage}>
              <FontAwesomeIcon icon={duotone('xmark-circle')} className="h-5 w-5 align-sub" />
            </div>
          </div>
        )}
        <div className="space-y-6 rounded bg-white p-6 shadow-xl">
          <div className="border-b pb-4">
            <div className="mb-6 justify-between md:flex">
              <Field
                label="Prénom"
                name="firstName"
                className="w-full md:mr-4 md:w-1/2"
                isRequired
                component={InputField}
              />{' '}
              <Field label="Nom" name="lastName" className="w-full md:w-1/2" isRequired component={InputField} />
            </div>
            <div className="justify-between md:flex">
              <Field
                label="Email"
                name="email"
                className="w-full md:mr-4 md:w-1/2"
                isRequired
                isDisabled
                component={InputField}
              />{' '}
              <Field label="Téléphone" name="phone" type="tel" className="w-full md:w-1/2" component={InputField} />
            </div>
            <Alert color="gray" shadow="shadow-none" className="mb-0 !p-2 text-xs">
              ☝&nbsp;Pour modifier votre email, contactez-nous en cliquant sur "Nous contacter" tout en bas de la page.
            </Alert>
          </div>
          <div className="border-b pb-4">
            <Field
              label="Adresse"
              name="addressLine1"
              placeholder="Ex : 16 rue du clos Benard 93300 Aubervilliers"
              isRequired
              className="w-full"
              component={InputField}
            />
          </div>
          <div>
            <Field label="COAD (Code Ayant-Droit)" name="coad" isRequired className="w-full" component={InputField} />
            <COADHint className="mt-2" />
          </div>
          <div className="mt-4">
            <button
              type="submit"
              className={cx('button w-full md:w-auto', {
                loading: isSubmitting,
              })}
              disabled={isSubmitting}
            >
              Mettre à jour
            </button>
          </div>
          {updateError && <GenericError className="mt-4" />}
        </div>
      </Form>
    );
  }
}

export default withFormik<Props, PersonnalDataFormValues>({
  enableReinitialize: true,
  mapPropsToValues: (props) => ({
    firstName: props.initialData.firstName || '',
    lastName: props.initialData.lastName || '',
    email: props.initialData.email || '',
    phone: props.initialData.phone || '',
    addressLine1: props.initialData.addressLine1 || '',
    coad: props.initialData.coad || '',
  }),

  validationSchema: PersonnalDataFormSchema,

  handleSubmit: (values, { props }) => props.handleSubmit(values),
})(PersonnalDataForm);
