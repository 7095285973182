import React, { ReactElement } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import COADImg from 'styles/assets/img/coad.png';
import TheModal, { TheModalProvider } from './TheModal';

Modal.setAppElement('#root');

interface COADHintProps {
  className?: string;
}

export default function COADHint({ className }: COADHintProps): ReactElement {
  return (
    <>
      <TheModalProvider>
        <TheModal.Trigger className="text-primary">
          <FontAwesomeIcon
            icon={icon({ name: 'question-circle', family: 'sharp', style: 'light' })}
            className="mr-2 h-4 flex-shrink-0"
          />
          Où trouver mon code ayant droit (COAD) ?
        </TheModal.Trigger>
        <TheModal icon="question" escClose={true}>
          <TheModal.Title>Où trouver votre code ayant droit (COAD) ?</TheModal.Title>
          <TheModal.Body>
            <p>
              Vous pouvez trouver ce code sur <strong>votre carte SACEM</strong> ou sur votre dernier{' '}
              <strong>feuillet de répartition</strong> :
            </p>
            <img src={COADImg} className="image" alt="Où trouver votre code ayant droit (COAD)" />
          </TheModal.Body>
          <TheModal.Footer>
            <TheModal.Button.Cancel theme="primary">J'ai compris</TheModal.Button.Cancel>
          </TheModal.Footer>
        </TheModal>
      </TheModalProvider>
    </>
  );
}
