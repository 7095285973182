import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import useUserInfo from '../../hooks/use-user-info';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTimeUpdates } from '../../utils/tsFormatter';
import { isLoadingFake } from '../../App';
import Squelton from '../../components/Squeleton';
import { useMonthlyRights } from 'hooks/use-rights';

export function RightsHistoricPage() {
  const {
    userInfo: { registerTs },
  } = useUserInfo();

  const { dailyUpdateRef } = useTimeUpdates(registerTs);
  const [currentMonth, setCurrentMonth] = useState(dayjs());
  // const [randomAmounts, setRandomAmounts] = useState<(number | string)[]>([]);

  const { monthlyRights, isLoadingMonthlyRights } = useMonthlyRights({
    month: currentMonth.month() + 1,
    year: currentMonth.year(),
  });

  // useEffect(() => {
  //   generateRandomAmounts();
  // }, [currentMonth, registerTs]);

  // const generateRandomAmounts = () => {
    const daysInMonth = currentMonth.daysInMonth();
    const randomAmounts = Array.from({ length: daysInMonth }, (_, index) => {
      const currentDate = currentMonth.date(index + 1);
      if (currentDate.isBefore(dayjs(registerTs).subtract(2, 'month'))) {
        return 'Pas encore inscrit à RightsNow!';
      } else if (currentDate.isAfter(dayjs(), 'day')) {
        return '--';
      } else {
        const rightsForTheDay = (monthlyRights || []).find((r) => dayjs(r.date).isSame(currentDate, 'day'));
        return rightsForTheDay?.amount || 0;
      }
    });
    // setRandomAmounts(amounts);
  // };

  const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedMonth = dayjs(event.target.value);
    setCurrentMonth(selectedMonth);
  };

  const loadPreviousMonth = () => {
    if (currentMonth.isAfter(dayjs(registerTs).subtract(2, 'month'))) {
      setCurrentMonth(currentMonth.subtract(1, 'month'));
    }
  };

  const loadNextMonth = () => {
    if (currentMonth.isBefore(dayjs(), 'month')) {
      setCurrentMonth(currentMonth.add(1, 'month'));
    }
  };

  const renderMonthOptions = () => {
    const options = [];
    const limitDate = dayjs(registerTs).subtract(2, 'month');
    let month = dayjs();

    while (month.isAfter(limitDate) || month.isSame(limitDate, 'month')) {
      options.push(
        <option key={month.format('YYYY-MM')} value={month.format('YYYY-MM')}>
          {month.format('MMMM YYYY')}
        </option>,
      );
      month = month.subtract(1, 'month');
    }

    return options;
  };

  return (
    <>
      <h1 className="h4 text-gray-800">Historique des droits générés</h1>
      <div className="w-full overflow-hidden rounded bg-white p-8 shadow-xl">
        <div className="mx-auto mb-6 flex flex-row">
          <button
            onClick={loadPreviousMonth}
            disabled={currentMonth.isBefore(dayjs(registerTs).subtract(2, 'month'))}
            className="flex shrink-0 flex-nowrap items-center rounded-l bg-primary px-4 py-2 text-white"
          >
            <FontAwesomeIcon
              icon={icon({ name: 'arrow-left', family: 'sharp', style: 'light' })}
              className="h-5 w-5 sm:mr-3"
            />
            <span className="hidden sm:inline-flex">Mois précédent</span>
          </button>
          <select
            onChange={handleMonthChange}
            value={currentMonth.format('YYYY-MM')}
            className="mb-0 rounded-none capitalize"
          >
            {renderMonthOptions()}
          </select>
          <button
            onClick={loadNextMonth}
            disabled={currentMonth.isSame(dayjs(), 'month')}
            className="flex shrink-0 flex-nowrap items-center rounded-r bg-primary px-4 py-2 text-white"
          >
            <span className="hidden sm:inline-flex">Mois suivant</span>
            <FontAwesomeIcon
              icon={icon({ name: 'arrow-right', family: 'sharp', style: 'light' })}
              className="h-5 w-5 sm:ml-3"
            />
          </button>
        </div>
        <div className="flow-root">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-0">
                      Date
                    </th>
                    <th scope="col" className="px-3.3 py-3.5 text-right text-sm text-gray-900">
                      Montant
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {randomAmounts.map((amount, index) => {
                    const date = currentMonth.date(index + 1);
                    const isToday = date.isSame(dayjs(), 'day');
                    const isFutureDate = date.isAfter(dayjs(), 'day');
                    return (
                      <tr key={index} className={isFutureDate ? 'text-gray-300' : ''}>
                        <td
                          className={`whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-0 ${isToday ? 'font-bold' : ''}`}
                        >
                          {date.format('DD/MM/YYYY')}
                        </td>
                        <td className="whitespace-nowrap py-2 pl-4 text-right text-sm sm:pl-0">
                          {isToday ? (
                            <span className="inline-flex items-center">
                              Disponible dans&nbsp;<span ref={dailyUpdateRef}></span>
                            </span>
                          ) : (
                            <>
                              {!isLoadingMonthlyRights ? (
                                amount.toLocaleString('fr-FR', {
                                  style: 'currency',
                                  currency: 'EUR',
                                  minimumFractionDigits: 0,
                                })
                              ) : (
                                <Squelton />
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
