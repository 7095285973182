import React from 'react';
import { Link } from 'react-router-dom';

interface CTAProps {
  href?: string;
  to?: string;
  className?: string;
  variant?: 'classic' | 'outline' | 'invert';
  color?: 'primary' | 'secondary' | 'green' | 'red';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  children: React.ReactNode;
}

const CTA: React.FC<CTAProps> = ({
  href,
  to,
  className = '',
  variant = 'classic',
  color = 'primary',
  size = 'md',
  children,
}) => {
  // const baseClasses = 'py-2 px-4 font-semibold rounded-lg inline-flex items-center justify-center';
  const baseClasses =
    'inline-flex items-center justify-center font-normal tracking-wide leading-none transition duration-200 transform rounded-md cursor-pointer hover:ring-4';

  const variantClasses = {
    classic: 'text-white outline-0 hover:text-white',
    outline: 'bg-transparent outline outline-1',
    invert: 'text-white bg-transparent',
  };

  const colorClasses = {
    primary: 'bg-primary hover:bg-primary-700 hover:ring-primary-200 outline-primary',
    secondary: 'text-white bg-secondary border-secondary',
    green: 'text-white bg-green-500 border-green-500',
    red: 'text-white bg-red-500 border-red-500',
  };

  const sizeClasses = {
    xs: 'px-3 py-2 text-xs',
    sm: 'px-4 py-2.5 text-sm',
    md: 'px-5 py-3 text-base',
    lg: 'px-6 py-3.5 text-lg',
    xl: 'px-8 py-4 text-xl',
  };

  const combinedClasses = `${baseClasses} ${variantClasses[variant]} ${colorClasses[color]} ${sizeClasses[size]} ${className}`;

  if (href) {
    return (
      <a href={href} className={combinedClasses}>
        {children}
      </a>
    );
  }

  if (to) {
    return (
      <Link to={to} className={combinedClasses}>
        {children}
      </Link>
    );
  }

  return <button className={combinedClasses}>{children}</button>;
};

export default CTA;
