import React from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';

export interface PaginationProps {
  current: number;
  pageSize: number;
  total: number;
  onChange: (page: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({ current, pageSize, total, onChange }) => {
  if (total === 0) return null;

  const pageCount = Math.floor((total - 1) / pageSize) + 1;

  const previousLinkClassName = cx(
    'relative',
    'inline-flex',
    'items-center',
    'px-4',
    'py-2',
    'rounded-l-md',
    'border',
    'border-gray',
    'bg-white',
    'flex-shrink-0',
    { hidden: current === 1 },
    { 'cursor-pointer': current !== 1 },
    { 'hover:bg-primary-50': current !== 1 },
  );
  const previousLink =
    current === 1 ? (
      <div className={previousLinkClassName}>
        <FontAwesomeIcon icon={duotone('arrow-left')} className="flex-shrink-0" />
        <span className="ml-3 hidden md:block">Précédent</span>
      </div>
    ) : (
      <a onClick={() => onChange(current - 1)} className={previousLinkClassName}>
        <FontAwesomeIcon icon={duotone('arrow-left')} className="flex-shrink-0" />
        <span className="ml-3 hidden md:block">Précédent</span>
      </a>
    );

  const nextLinkClassName = cx(
    'relative',
    'inline-flex',
    'items-center',
    'px-4',
    'py-2',
    'rounded-r-md',
    'border',
    'border-gray',
    'bg-white',
    'flex-shrink-0',
    { hidden: current === pageCount },
    { 'cursor-pointer': current !== pageCount },
    { 'hover:bg-primary-50': current !== pageCount },
  );
  const nextLink =
    current === pageCount ? (
      <div className={nextLinkClassName}>
        <span className="hidden md:block">Suivant</span>
        <FontAwesomeIcon icon={duotone('arrow-right')} />
        <FontAwesomeIcon icon={duotone('arrow-right')} className="flex-shrink-0 md:ml-3 md:block" />
      </div>
    ) : (
      <a onClick={() => onChange(current + 1)} className={nextLinkClassName}>
        <span className="hidden md:block">Suivant</span>
        <FontAwesomeIcon icon={duotone('arrow-right')} className="flex-shrink-0 md:ml-3 md:block" />
      </a>
    );

  // const pageItems = [];
  /*for (let i = 1; i <= pageCount; i++) {
    pageItems.push(
      <a
        key={i}
        className={cx(
          'relative inline-flex items-center px-4 py-2 border cursor-pointer hover:bg-primary-50',
          {
            'z-10 bg-primary-100 border-primary text-primary font-bold':
              i === current,
          },
        )}
        onClick={() => onChange(i)}
      >
        {i}
      </a>,
    );
  }*/

  const pageItems = (
    <div className="relative inline-flex cursor-default items-center border px-4 py-2">
      {current}/{pageCount}
    </div>
  );

  return (
    <div className="mt-6 md:flex md:items-center md:justify-between">
      <div className="mb-4 w-auto text-center md:mb-0 md:text-left">
        <p className="text-gray-500">
          Nombre de titres&nbsp;:&nbsp;
          <span className="font-bold">{total}</span>
        </p>
      </div>
      <div className="flex justify-center md:justify-end">
        <nav className="relative z-0 inline-flex select-none -space-x-px rounded-md shadow-sm" aria-label="Pagination">
          {previousLink} {pageItems} {nextLink}
        </nav>
      </div>
    </div>
  );
};
