import React from 'react';
import Alert from './Alert';

interface GenericErrorProps {
  className?: string;
}
export default function GenericError({ className }: GenericErrorProps) {
  return (
    <>
      <Alert color="red" title="Oups !" shadow="shadow-none">
        Une erreur est survenue mais ce n'est pas de votre faute. <br /> Merci de réessayer dans 1 ou 2 minutes.
      </Alert>
    </>
  );
}
