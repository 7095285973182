import React from 'react';
import { Link } from 'react-router-dom';

type BannerProps = {
  color?: 'primary' | 'secondary' | 'neutral' | 'yellow';
  shadow?: 'shadow' | 'shadow-xl' | 'shadow-none';
  children: React.ReactNode;
  linkTo?: string;
  linkLabel?: string;
  className?: string;
};

const Banner: React.FC<BannerProps> = ({ color, shadow = 'shadow', children, linkTo, linkLabel, className }) => {
  let bgColor, textColor, linkColor;

  switch (color) {
    case 'yellow':
      bgColor = 'bg-yellow-200';
      textColor = 'text-black';
      linkColor = 'text-black hover:text-black/80';
      break;
    case 'primary':
      bgColor = 'bg-primary';
      textColor = 'text-white';
      linkColor = 'text-white hover:text-white/80';
      break;
    case 'secondary':
      bgColor = 'bg-secondary';
      textColor = 'text-white';
      linkColor = 'text-white hover:text-white/80';
      break;
    default:
      bgColor = 'bg-secondary';
      textColor = 'text-white';
      linkColor = 'text-white hover:text-white/80';
  }

  return (
    <div
      role="banner"
      className={`relative mx-auto max-w-screen-xl rounded-lg px-4 md:mt-2 md:px-6 lg:px-8 ${className}`}
    >
      <div
        className={`flex items-center justify-between gap-x-6 rounded px-4 py-2 text-sm ${textColor} ${bgColor} ${shadow}`}
      >
        <div className="leading-6">{children}</div>
        {linkTo && linkLabel && (
          <Link to={linkTo} className={`underline ${linkColor}`}>
            {linkLabel}
          </Link>
        )}
      </div>
    </div>
  );
};

export default Banner;
