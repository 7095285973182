import React from 'react';
import Alert from '../../components/Alert';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function TracksVerificationConfirmation() {
  return (
    <div className="w-full max-w-3xl">
      <Alert color="green" title="Merci !">
        <p>Le mandat a bien été édité et envoyé à la Sacem.</p>
      </Alert>
      <div className="w-full overflow-hidden rounded bg-white p-6 shadow-xl">
        <p className="text-lg">
          <strong>Que se passe t-il ensuite ?</strong>
        </p>
        <p>
          Dès que nous avons la confirmation de la Sacem (généralement sous 24h ouvrées), nous allons vérifier plusieurs
          choses :
        </p>
        <div className="flow-root">
          <ul role="list">
            <li>
              <div className="relative pb-8">
                <span className="absolute left-5 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                <div className="relative flex space-x-3">
                  <div>
                    <span className="flex h-10 w-10 items-center justify-center rounded-full bg-primary ring-8 ring-white">
                      <FontAwesomeIcon
                        icon={icon({ name: 'id-card', family: 'sharp', style: 'light' })}
                        className="h-5 text-white"
                      />
                    </span>
                  </div>
                  <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                    <div>
                      <p>
                        <strong>
                          Que votre identité correspond aux données lors de votre inscription sur RightsNow!
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="relative pb-8">
                <span className="absolute left-5 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                <div className="relative flex space-x-3">
                  <div>
                    <span className="flex h-10 w-10 items-center justify-center rounded-full bg-primary ring-8 ring-white">
                      <FontAwesomeIcon
                        icon={icon({ name: 'user-check', family: 'sharp', style: 'light' })}
                        className="h-5 text-white"
                      />
                    </span>
                  </div>
                  <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                    <div>
                      <p>
                        <strong>Si vous êtes bien le ou l'un des ayants droit des titres déclarés</strong> (nous
                        réajusterons la partie "Mes titres" le cas échéant)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="relative pb-8">
                <div className="relative flex space-x-3">
                  <div>
                    <span className="flex h-10 w-10 items-center justify-center rounded-full bg-primary ring-8 ring-white">
                      <FontAwesomeIcon
                        icon={icon({ name: 'key-skeleton-left-right', family: 'sharp', style: 'light' })}
                        className="h-5 text-white"
                      />
                    </span>
                  </div>
                  <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                    <div>
                      <p>
                        <strong>Les clés de répartition</strong> (votre quote part sur chaque oeuvre)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <p>
          Une fois les vérifications réalisées, nous ajusterons le montant de l'avance disponible avec les nouvelles
          données.
        </p>
        <p className="text-red">
          <strong>IMPORTANT</strong> : nous ne proposons pas d'avance sur des diffusions antérieures à votre inscription
          sur RightsNow!
        </p>
      </div>
    </div>
  );
}
