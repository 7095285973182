import React from 'react';

type SkeletonProps = {
  type?: 'text' | 'paragraph' | 'table' | 'number' | 'card' | 'chart';
  color?: 'gray' | 'green' | 'red' | 'blue' | 'yellow' | 'orange' | 'white';
  height?: 'xs' | 'sm' | 'md' | 'normal' | 'lg' | 'xl';
  width?: 'xs' | 'sm' | 'md' | 'normal' | 'lg' | 'xl';
  className?: string;
  col?: number;
  row?: number;
  lines?: number;
  bars?: number; // Nombre de barres pour le type chart
};

const getRandomHeightClass = () => {
  const classes = ['h-1/6', 'h-3/4', 'h-3/5', 'h-1/5', 'h-1/2', 'h-2/3', 'h-full'];
  return classes[Math.floor(Math.random() * classes.length)];
};

const getRandomWidthClass = () => {
  const classes = ['w-1/6', 'w-3/4', 'w-3/5', 'w-1/5', 'w-1/2', 'w-2/3'];
  return classes[Math.floor(Math.random() * classes.length)];
};

const getRandomWidthClassPx = () => {
  const classes = ['w-32', 'w-16', 'w-10', 'w-6', 'w-3'];
  return classes[Math.floor(Math.random() * classes.length)];
};

const getRandomWordCount = () => {
  return Math.floor(Math.random() * 6) + 3; // Entre 3 et 12 mots
};

const Skeleton: React.FC<SkeletonProps> = ({
  color = 'gray',
  type = 'number',
  height = 'normal',
  width = 'normal',
  className = '',
  col = 3, // Nombre de colonnes par défaut pour le type table
  row = 5, // Nombre de lignes par défaut pour le type table
  lines = 3, // Nombre de lignes par défaut pour le type paragraph
  bars = 20, // Nombre de barres par défaut pour le type chart
}) => {
  let bgColor: string;
  let heightSize: string;
  let widthSize: string;
  const getClassNames = () => {
    return `skeleton ${color} ${height} ${width} ${className}`;
  };

  switch (color) {
    case 'gray':
      bgColor = 'bg-gray-300/70';
      break;
    case 'green':
      bgColor = 'bg-green-300/70';
      break;
    case 'red':
      bgColor = 'bg-red-300/70';
      break;
    case 'blue':
      bgColor = 'bg-blue-300/70';
      break;
    case 'yellow':
      bgColor = 'bg-yellow-300/70';
      break;
    case 'orange':
      bgColor = 'bg-orange-300/70';
      break;
    case 'white':
      bgColor = 'bg-white/70';
      break;
    default:
      bgColor = 'bg-gray-300/70';
  }

  switch (height) {
    case 'xs':
      heightSize = 'h-1';
      break;
    case 'sm':
      heightSize = 'h-2';
      break;
    case 'md':
      heightSize = 'h-2.5';
      break;
    case 'normal':
      heightSize = 'h-3';
      break;
    case 'lg':
      heightSize = 'h-4';
      break;
    case 'xl':
      heightSize = 'h-5';
      break;
    default:
      heightSize = 'h-3';
  }

  switch (width) {
    case 'xs':
      widthSize = 'w-2';
      break;
    case 'sm':
      widthSize = 'w-5';
      break;
    case 'md':
      widthSize = 'w-7';
      break;
    case 'normal':
      widthSize = 'w-9';
      break;
    case 'lg':
      widthSize = 'w-12';
      break;
    case 'xl':
      widthSize = 'w-16';
      break;
    default:
      widthSize = 'w-9';
  }

  const renderTableContent = () => {
    const columns = Array.from({ length: col }, (_, index) => (
      <th key={index} scope="col" className="py-3.5 pr-3 text-left text-sm text-gray-900">
        <div className={`${heightSize} animate-pulse rounded-full ${bgColor} ${getRandomWidthClass()}`}></div>
      </th>
    ));

    const rows = Array.from({ length: row }, (_, rowIndex) => (
      <tr key={rowIndex}>
        {Array.from({ length: col }, (_, colIndex) => (
          <td key={colIndex} className="whitespace-nowrap py-2 pr-3 text-sm">
            <div className={`${heightSize} animate-pulse rounded-full ${bgColor} ${getRandomWidthClass()}`}></div>
          </td>
        ))}
      </tr>
    ));

    return (
      <>
        <thead>
          <tr>{columns}</tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">{rows}</tbody>
      </>
    );
  };

  const renderParagraphContent = () => {
    const paragraphs = Array.from({ length: lines }, (_, lineIndex) => {
      const wordCount = getRandomWordCount();
      const words = Array.from({ length: wordCount }, (_, wordIndex) => (
        <span
          key={wordIndex}
          className={`${heightSize} animate-pulse rounded-full ${bgColor} ${getRandomWidthClassPx()}`}
        ></span>
      ));
      return (
        <div key={lineIndex} className={`flex space-x-1`}>
          {words}
        </div>
      );
    });

    return <div className="space-y-2">{paragraphs}</div>;
  };

  const renderChartContent = () => {
    const barsContent = Array.from({ length: bars }, (_, barIndex) => (
      <div
        key={barIndex}
        className={`rounded-t-lg ${getRandomHeightClass()} ${widthSize} animate-pulse ${bgColor} mx-1`}
      ></div>
    ));

    return (
      <div className="flex h-full w-full flex-row items-baseline justify-start space-x-1 overflow-hidden">
        {barsContent}
      </div>
    );
  };

  const renderContent = () => {
    switch (type) {
      case 'text':
        return (
          <span className="inline-flex h-3 w-8 animate-pulse">
            <span className={`inline-flex ${heightSize} ${widthSize} rounded-full ${bgColor}`}></span>
          </span>
        );
      case 'paragraph':
        return renderParagraphContent();
      case 'table':
        return <table className="min-w-full divide-y divide-gray-300">{renderTableContent()}</table>;
      case 'number':
        return (
          <span className="inline-flex h-3 animate-pulse">
            <span className={`inline-flex ${heightSize} ${widthSize} rounded-full ${bgColor}`}></span>
          </span>
        );
      case 'card':
        return <span className="bold bg-yellow-500">WIP !</span>;
      case 'chart':
        return renderChartContent();
      default:
        return null;
    }
  };

  return (
    <div className={`${getClassNames()} ${type === 'number' || type === 'text' ? 'inline-flex' : ''}`}>
      {renderContent()}
      <span className="sr-only">Chargement...</span>
    </div>
  );
};

export default Skeleton;
