import { useQuery } from 'react-query';
import { TimeRangeInterval, UserInfoDTO } from 'shared-types';
import api from 'services/api';
import { getTimeRangeInterval } from 'features/dashboard/BroadcastChart';
import useRights from 'hooks/use-rights';
import useCashOut from 'hooks/use-cash-out';

export default function useAvailableCashOutAmount() {
  const { rights, isLoadingRights, isErrorRights } = useRights();
  const { cashOuts, isLoadingCashOuts, isErrorCashOuts } = useCashOut();
  let totalCashedOut;
  let amountToBeCashedOut;
  if (rights && cashOuts) {
    const totalRights = rights.streams + rights.radio + rights.tv;
    totalCashedOut = cashOuts
      .filter((c) => ['AWAITING_SACEM_VALIDATION', 'ONGOING_TRANSFER', 'COMPLETED'].includes(c.status))
      .reduce((acc, curr) => acc + curr.amount, 0);
    const amount = totalRights - totalCashedOut > 0 ? totalRights - totalCashedOut : 0;
    amountToBeCashedOut = amount
    if (amount >= 10 && amount < 100) {
      amountToBeCashedOut = 10;
    } else if (amount >= 100 && amount < 1000) {
      amountToBeCashedOut = 100;
    } else if (amount >= 1000 && amount < 5000) {
      amountToBeCashedOut = 500;
    } else if (amount >= 5000) {
      amountToBeCashedOut = 1000;
    }
  }
  return {
    totalCashedOut,
    amountToBeCashedOut,
    isLoadingAmountToBeCashedOut: isLoadingRights || isLoadingCashOuts,
    isErrorAmountToBeCashedOut: isErrorRights || isErrorCashOuts,
  };
}
