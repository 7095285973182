import PageTitle from 'features/layout/PageTitle';
import React from 'react';
import { useLocation } from 'react-router-dom';
import RegisterWizard from './RegisterWizard';

export default function RegisterPage() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const source = query.get('source')
  return (
    <div className="flex justify-center subscription">
      <PageTitle title="Inscription" />
      <RegisterWizard initialSource={source || undefined} />
    </div>
  );
}
