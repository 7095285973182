import React, { useState, useRef, MutableRefObject, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { userStatus } from '../shared-types/user-status';
import { Dialog, Transition } from '@headlessui/react';
import { cashOutStatusDetails } from '../shared-types/cash-out-status';
import Badge from './Badge';

const CashOutStatusLegend: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <button className="flex items-center text-xs text-primary" onClick={handleOpenModal}>
        <FontAwesomeIcon
          icon={icon({ name: 'question-circle', family: 'sharp', style: 'light' })}
          className="mr-2 h-4 flex-shrink-0"
        />
        Information sur les statuts
      </button>
      <LegendModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </>
  );
};

function LegendModal({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="sm:scale-100l relative inline-block translate-y-0 transform overflow-hidden rounded bg-white text-left align-bottom opacity-100 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:align-middle">
                <div className="bg-white px-4 py-4 sm:flex sm:items-start sm:p-6">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <FontAwesomeIcon
                      icon={icon({ name: 'circle-question', family: 'sharp', style: 'light' })}
                      className="text-blue"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-xl leading-loose">
                      Légende des statuts
                    </Dialog.Title>
                    <div className="mt-6">
                      <dl className="divide-y divide-gray-200">
                        {cashOutStatusDetails.map((status, index) => (
                          <div key={index} className="py-6 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm">
                              <div className={status.color}>
                                <Badge content={status.name} color={status.color} icon={status.icon} />
                              </div>
                            </dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                              {status.description}
                            </dd>
                          </div>
                        ))}
                      </dl>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-100 px-6 py-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="button inline-flex w-full justify-center sm:w-auto"
                    onClick={onClose}
                  >
                    J'ai compris
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default CashOutStatusLegend;
