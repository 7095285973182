import React from 'react';
import { Link } from 'react-router-dom';
import useUserInfo from '../../hooks/use-user-info';
import { useTimeUpdates } from '../../utils/tsFormatter';

export function CardTracksMonitoringInfo() {
  const {
    userInfo: { registerTs },
    isLoading,
  } = useUserInfo();

  const { elapsedTimeRef, dailyUpdateRef } = useTimeUpdates(registerTs);

  return (
    <div className="flex items-center justify-between rounded-t bg-green-800 px-6 py-4 text-sm text-white shadow">
      <div>
        <Link to="/channels-monitoring" className="text-green-200 underline hover:text-white">
          Monitoring
        </Link>{' '}
        de vos{' '}
        <Link to="/my-tracks" className="text-green-200 underline hover:text-white">
          titres
        </Link>{' '}
        en cours {!isLoading && <>depuis <span ref={elapsedTimeRef}></span>.</>}
      </div>
      <div className="ml-2 inline-flex items-center rounded-md border border-white bg-red-700 px-1 text-[11px] font-bold text-white shadow">
        <span className="mr-1 inline-block h-2 w-2 animate-pulse rounded-full bg-white"></span>
        <span>REC</span>
      </div>
    </div>
  );
}
