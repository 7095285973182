import React, { MouseEvent, ReactElement, useEffect, useState } from 'react';
import RightsNowLogo from 'components/svg/RightsNowLogo';
import IconArrow from 'components/icons/IconArrow';
import { SpotifyAlbum, SpotifyArtist, SpotifyTrack } from 'shared-types';
import SearchSpotify from './SearchSpotify';
import TrackUpload from './TrackUpload';
import { FileStatusChangeHandler, IFileWithMeta } from '../../components/Dropzone';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';
import PageTitle from '../layout/PageTitle';
import GenericError from 'components/GenericError';
import { isWretcherError } from 'utils/errors';
import Alert from 'components/Alert';

export interface TrackData {
  selectedArtists: SpotifyArtist[];
  selectedTracks: SpotifyTrack[];
  selectedAlbums: SpotifyAlbum[];
  uploadedTracks: IFileWithMeta[];
}

interface SearchTrackProps {
  onNext: (data: TrackData) => void;
  onPrevious: () => void;
  disableNext?: boolean;
  initialValues?: TrackData;
  error?: any;
}

function AddTrack({ onNext, onPrevious, disableNext, initialValues, error }: SearchTrackProps): ReactElement {
  const [isUploadTrackMode, setIsUploadTrackMode] = useState(false);

  const [selectedArtists, setSelectedArtists] = useState<SpotifyArtist[]>(initialValues?.selectedArtists || []);
  const [selectedTracks, setSelectedTracks] = useState<SpotifyTrack[]>(initialValues?.selectedTracks || []);
  const [selectedAlbums, setSelectedAlbums] = useState<SpotifyAlbum[]>(initialValues?.selectedAlbums || []);
  const [uploadedTracks, setUploadedTracks] = useState<IFileWithMeta[]>(initialValues?.uploadedTracks || []);

  const toggleTrackUploadMode = (active: boolean) => {
    setIsUploadTrackMode(active);
  };

  const toggleTrackSelection = (track: SpotifyTrack) => {
    const isSelectedTrack = selectedTracks.indexOf(track) > -1;
    if (isSelectedTrack) {
      setSelectedTracks(selectedTracks.filter((t) => t !== track));
    } else {
      setSelectedTracks([...selectedTracks, track]);
    }
  };

  const toggleArtistSelection = (artist: SpotifyArtist) => {
    const isSelectedArtist = selectedArtists.indexOf(artist) > -1;
    if (isSelectedArtist) {
      setSelectedArtists(selectedArtists.filter((t) => t !== artist));
    } else {
      setSelectedArtists([...selectedArtists, artist]);
    }
  };

  const toggleAlbumSelection = (album: SpotifyAlbum) => {
    const isSelectedAlbum = selectedAlbums.indexOf(album) > -1;
    if (isSelectedAlbum) {
      setSelectedAlbums(selectedAlbums.filter((t) => t !== album));
    } else {
      setSelectedAlbums([...selectedAlbums, album]);
    }
  };

  const handleOnNext = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onNext({ selectedArtists, selectedTracks, selectedAlbums, uploadedTracks });
  };

  const handleOnPrevious = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onPrevious();
  };

  const handleTrackUploadOnUpdate = (tracks: IFileWithMeta[]) => {
    setUploadedTracks(tracks);
  };

  let displayedError = undefined;
  if (error) {
    if (isWretcherError(error) && error.message.includes('Email already exists')) {
      displayedError = (
        <div className="mt-4">
          <Alert color="red" title="Oups !" shadow="shadow-none">
            Un compte existe déjà avec cet email. Veuillez choisir un autre email.
          </Alert>
        </div>
      );
    } else {
      displayedError = (
        <div className="mt-4">
          <GenericError />
        </div>
      );
    }
  }

  const selectedItemCount =
    selectedTracks.length +
    selectedAlbums.map((alb) => alb.total_tracks).reduce((a, b) => a + b, 0) +
    selectedArtists.length;
  return (
    <>
      <PageTitle title="Inscription - Recherche" />
      <div className="mx-auto flex w-full justify-center lg:my-6 lg:px-6 xl:my-12 xl:max-w-screen-xl">
        <div className="w-full rounded-lg bg-white p-10">
          <div className="mb-12 w-64">
            <a href="https://rightsnow.fr/" target="_blank" rel="noreferrer">
              {' '}
              <RightsNowLogo />{' '}
            </a>
          </div>
          <h4>Rechercher et ajouter mes titres</h4>
          {/*Upload module*/}
          <div>
            {!isUploadTrackMode && (
              <>
                <p>
                  Retrouvez vos titres sur <strong className="text-[#1ED760]">Spotify</strong> pour les ajouter à votre
                  compte RightsNow!.{' '}
                  <strong className="bg-yellow-300">
                    Bien sur, vous pourrez ajouter d'autres titres par la suite.
                  </strong>
                </p>
              </>
            )}
            {isUploadTrackMode && (
              <a onClick={() => setIsUploadTrackMode(false)} className="inline-flex">
                {' '}
                <IconArrow className="mr-2 w-3" /> Revenir à la recherche Spotify{' '}
              </a>
            )}
          </div>
          {!isUploadTrackMode && (
            <SearchSpotify
              selectedArtists={selectedArtists}
              selectedTracks={selectedTracks}
              selectedAlbums={selectedAlbums}
              toggleArtistSelection={toggleArtistSelection}
              toggleTrackSelection={toggleTrackSelection}
              toggleAlbumSelection={toggleAlbumSelection}
            />
          )}
          {isUploadTrackMode && (
            <TrackUpload onUpdate={handleTrackUploadOnUpdate} initialFiles={initialValues?.uploadedTracks || []} />
          )}
          <div className="mt-6 flex justify-between">
            <button className="button text-primary outline" onClick={handleOnPrevious}>
              <IconArrow />
            </button>
            {selectedItemCount === 0 && (
              <Tippy content="Sélectionnez au moins un artiste, titre ou ablum">
                <span>
                  <button className="button" onClick={handleOnNext} disabled={selectedItemCount === 0 || disableNext}>
                    Valider
                  </button>
                </span>
              </Tippy>
            )}
            {selectedItemCount > 0 && (
              <button className="button" onClick={handleOnNext} disabled={selectedItemCount === 0 || disableNext}>
                Valider
              </button>
            )}
          </div>
          {displayedError}
        </div>
      </div>
    </>
  );
}

export default AddTrack;
