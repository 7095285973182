import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type BadgeProps = {
  content?: string;
  color?: string //'gray' | 'green' | 'red' | 'blue' | 'yellow' | 'orange';
  icon?: any;
  className?: string;
};

const Badge: React.FC<BadgeProps> = ({ content, color, icon, className }) => {
  let bgColor, textColor;

  switch (color) {
    case 'gray':
      bgColor = 'bg-gray-100';
      textColor = 'text-gray-800';
      break;
    case 'green':
      bgColor = 'bg-green-100';
      textColor = 'text-green-800';
      break;
    case 'red':
      bgColor = 'bg-red-100';
      textColor = 'text-red-800';
      break;
    case 'blue':
      bgColor = 'bg-blue-100';
      textColor = 'text-blue-800';
      break;
    case 'yellow':
      bgColor = 'bg-yellow-100';
      textColor = 'text-yellow-900';
      break;
    case 'orange':
      bgColor = 'bg-orange-100';
      textColor = 'text-orange-800';
      break;
    default:
      bgColor = 'bg-gray-100';
      textColor = 'text-gray-800';
  }

  return (
    <span className={`rounded-full ${bgColor} px-2.5 py-0.5 text-sm font-medium ${textColor} ${className}`}>
      <FontAwesomeIcon icon={icon} className="mr-1" />
      {content}
    </span>
  );
};

export default Badge;
