import React, { useEffect, useState } from 'react';
import useUserInfo from '../../hooks/use-user-info';
import { useQuery } from 'react-query';
import api from '../../services/api';
import Alert from '../../components/Alert';

declare global {
  interface Window {
    Tally: {
      loadEmbeds: () => void;
    };
  }
}

// Day's date
const currentDate: Date = new Date();
const day: number = currentDate.getDate();
const month: number = currentDate.getMonth() + 1;
const year: number = currentDate.getFullYear();
// Add a "0" if necessary
const formattedDate: string = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;

export function TracksVerificationForm() {
  const { data } = useQuery('/api/personnal_data', () => api.fetchPersonalData());
  const {
    userInfo: { email },
  } = useUserInfo();

  useEffect(() => {
    const widgetScriptSrc = 'https://tally.so/widgets/embed.js';

    const load = () => {
      // Load Tally embeds
      if (typeof window.Tally !== 'undefined') {
        window.Tally.loadEmbeds();
        return;
      }

      // Fallback if window.Tally is not available
      document.querySelectorAll('iframe[data-tally-src]:not([src])').forEach((iframeEl) => {
        if (iframeEl instanceof HTMLIFrameElement && iframeEl.dataset.tallySrc) {
          iframeEl.src = iframeEl.dataset.tallySrc;
        }
      });
    };

    // If Tally is already loaded, load the embeds
    if (typeof window.Tally !== 'undefined') {
      load();
      return;
    }

    // If the Tally widget script is not loaded yet, load it
    if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
      const script = document.createElement('script');
      script.src = widgetScriptSrc;
      script.onload = load;
      script.onerror = load;
      document.body.appendChild(script);
      return;
    }
  }, []);

  return (
    <div className="w-full max-w-3xl">
      <h1 className="h4 text-gray-800">Vérification de mes titres </h1>
      <div className="w-full overflow-hidden rounded bg-white p-6 shadow-xl">
        <Alert color="blue">
          <p>
            Pour vérifier vos oeuvres, nous avons besoin d'avoir un <strong>mandat Sacem</strong>. Ce mandat nous
            permettra de vérifier auprès de la Sacem que vous êtes bien l'ayant droit.
          </p>
          <p>
            Ce mandat est <strong>gratuit</strong> et vous pouvez le révoquer à tout moment.
          </p>
        </Alert>
        <iframe
          src={`https://tally.so/embed/3qBPLG?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1&email=${email}&firstName=${data?.firstName}&lastName=${data?.lastName}&id=${data?.id}&address=${data?.addressLine1}&todayDate=${formattedDate}`}
          loading="lazy"
          width="100%"
          height="432"
          frameBorder={0}
          marginHeight={0}
          marginWidth={0}
          className="mt-4"
        ></iframe>
      </div>
    </div>
  );
}
