import React from 'react';
import 'tippy.js/dist/tippy.css';
import './styles/output.css';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import LoginPage from 'features/login/LoginPage';
import { PrivateRoute, ProvideAuth } from 'hooks/use-auth';
import Page404 from 'features/404/Page404';
import SettingsLayout from 'features/layout/SettingsLayout';
import ConnectedUserLayout from 'features/layout/ConnectedUserLayout';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import DashboardPage from 'features/dashboard/DashboardPage';
import MyTracksPage from 'features/tracks/MyTracksPage';
import AccountPage from 'features/settings/AccountPage';
import PartnerOffersPage from 'features/settings/PartnerOffersPage';
import NotificationPage from 'features/notification/NotificationPage';
import NotificationSettingsPage from 'features/settings/NotificationSettingsPage';
import TrackUploadPage from 'features/tracks/TrackUploadPage';
import RegisterPage from 'features/register/RegisterPage';
import EmailVerification from 'features/register/EmailVerification';
import IdentityUploadPage from 'features/identity-upload/IdentityUploadPage';
import { UnauthorizedError } from 'services/api';
import ForgottenPasswordPage from 'features/login/ForgottenPasswordPage';
import SubscriptionSettingsPage from 'features/settings/subscription/SubscriptionSettingsPage';
import ChangePasswordPage from './features/settings/ChangePasswordPage';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PastDuePage from 'features/subscription/PastDuePage';
import SubscribePage from 'features/subscription/SubscribePage';
import AddTrack from './features/register/AddTrack';
import LogoutPage from 'features/login/LogoutPage';
import CancelPage from './features/subscription/CanceledPage';
import ChannelsMonitoring from './features/channels-monitoring/ChannelsMonitoring';
import CashOutHistoric from './features/cash-out/CashOutHistoric';
import CashOutRequest from './features/cash-out/CashOutRequest';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import localeData from 'dayjs/plugin/localeData';
import RightsHistoric from './features/cash-out/RightsHistoric';
import { TracksVerificationForm } from './features/tracks/TracksVerificationForm';
import AllButtons from './styles/AllButtons';
import { TracksVerificationConfirmation } from './features/tracks/TracksVerificationConfirmation';
import BankPage from 'features/settings/BankPage'

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(localeData);
dayjs.locale('fr');

// export const NewUser: boolean = true;
export const activeEmptyState: boolean = false;
export const isLoadingFake: boolean = false;
export const LoadingState: boolean = false;
export const ErrorState: boolean = false;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!, { locale: 'fr' });

const queryCacheOnErrorHandler = (error: unknown) => {
  if (error instanceof UnauthorizedError) {
    console.error('Unauthorized error, redirect to login');
    window.location.href = '/login';
  }
};

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: queryCacheOnErrorHandler,
  }),
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        console.log(`retry failureCount=${failureCount}, error: ${error}`);
        return !(error instanceof UnauthorizedError) && failureCount <= 2;
      },
    },
  },
});

function App() {
  return (
    <Elements stripe={stripePromise}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ProvideAuth>
            <Routes>
              <Route
                index
                element={
                  <PrivateRoute>
                    <ConnectedUserLayout>
                      <DashboardPage />
                    </ConnectedUserLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    <ConnectedUserLayout>
                      <DashboardPage />
                    </ConnectedUserLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/past-due"
                element={
                  <PrivateRoute>
                    <PastDuePage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/canceled-subscription"
                element={
                  <PrivateRoute>
                    <CancelPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/my-tracks"
                element={
                  <PrivateRoute>
                    <ConnectedUserLayout>
                      <MyTracksPage />
                    </ConnectedUserLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/my-tracks/verification"
                element={
                  <PrivateRoute>
                    <ConnectedUserLayout>
                      <TracksVerificationForm />
                    </ConnectedUserLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/my-tracks/verification/confirmation"
                element={
                  <PrivateRoute>
                    <ConnectedUserLayout>
                      <TracksVerificationConfirmation />
                    </ConnectedUserLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/track-upload"
                element={
                  <PrivateRoute>
                    <ConnectedUserLayout>
                      <TrackUploadPage />
                    </ConnectedUserLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/rights-historic"
                element={
                  <PrivateRoute>
                    <ConnectedUserLayout>
                      <RightsHistoric />
                    </ConnectedUserLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/cash-out-historic"
                element={
                  <PrivateRoute>
                    <ConnectedUserLayout>
                      <CashOutHistoric />
                    </ConnectedUserLayout>
                  </PrivateRoute>
                }
              />

              <Route
                path="/cash-out-request"
                element={
                  <PrivateRoute>
                    <ConnectedUserLayout>
                      <CashOutRequest />
                    </ConnectedUserLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/channels-monitoring"
                element={
                  <PrivateRoute>
                    <ConnectedUserLayout>
                      <ChannelsMonitoring />
                    </ConnectedUserLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/subscribe"
                element={
                  <PrivateRoute>
                    <SubscribePage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <PrivateRoute>
                    <ConnectedUserLayout>
                      <SettingsLayout>
                        <Outlet />
                      </SettingsLayout>
                    </ConnectedUserLayout>
                  </PrivateRoute>
                }
              >
                <Route path="account" element={<AccountPage />} />
                <Route path="password" element={<ChangePasswordPage />} />
                <Route path="bank" element={<BankPage />} />
                <Route path="offers" element={<PartnerOffersPage />} />
                <Route path="notications" element={<NotificationSettingsPage />} />
                <Route path="subscription" element={<SubscriptionSettingsPage />} />
                <Route path="identity-upload" element={<IdentityUploadPage />} />
              </Route>
              <Route
                path="/notifications"
                element={
                  <PrivateRoute>
                    <ConnectedUserLayout>
                      <NotificationPage />
                    </ConnectedUserLayout>
                  </PrivateRoute>
                }
              />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/search-track" element={<AddTrack onPrevious={() => {}} onNext={() => {}} />} />
              <Route path="/email-verification" element={<EmailVerification />} />
              <Route path="/forgotten-password" element={<ForgottenPasswordPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/logout" element={<LogoutPage />} />
              <Route path="*" element={<Page404 />} />
              <Route path="/buttons" element={<AllButtons />} />
            </Routes>
          </ProvideAuth>
        </QueryClientProvider>
      </BrowserRouter>
    </Elements>
  );
}

export default App;
