import React from 'react';
import RightNowLogo from 'components/svg/RightsNowLogo';
import IconSubscription from '../../components/icons/IconSubscription';
import StripeLogo from '../../components/svg/StripeLogo';
import api from 'services/api';
import { useNavigate } from 'react-router-dom';
import IconLoading from '../../components/icons/IconLoading';
import GenericError from '../../components/GenericError';
import useUserInfo from 'hooks/use-user-info';
import Loader from '../../components/Loader';

export default function SubscribePage() {
  const navigate = useNavigate();
  const {
    userInfo: { subscription },
    isLoading,
    isError,
  } = useUserInfo();

  if (isLoading) {
    return (
      <div className="flex h-56 items-center justify-center text-sm leading-none">
        <Loader type="vertical" />
      </div>
    );
  }

  if (isError) {
    return <GenericError />;
  }

  if (subscription !== undefined) {
    navigate('/');
  }
  return (
    <>
      <div className="mx-auto flex min-h-full max-w-2xl flex-col pt-16">
        <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col px-4 sm:px-6 lg:px-8">
          <div className="mb-12 flex flex-shrink-0">
            <span className="sr-only">RightsNow!</span> <RightNowLogo className="h-12 w-auto" />
          </div>
          <h1 className="flex text-xl text-green">
            <IconSubscription className="mr-2 w-6 flex-shrink-0" />
            <strong>Évolution de votre abonnement</strong>
          </h1>
          <p> Suite au changement du mode d’abonnement, merci de mettre à jour votre moyen de paiement. </p>
          <p>
            <strong>En tant qu’ancien·e membre, vous profitez AUTOMATIQUEMENT d'1 mois gratuit 🎉.</strong>
          </p>

          <div className="message mb-6 mt-3 bg-yellow-50 shadow">
            <p>
              Sans action de votre part, le monitoring de vos titres sera automatiquement désactivé le XX/XX/XXXX à
              minuit et <strong>votre compte sera archivé</strong>.
            </p>
          </div>

          <p>
            Si vous le souhaitez, vous pouvez également <a onClick={() => {}}>supprimer votre compte</a> dès maintenant.
          </p>

          <div className="mt-6 flex flex-col items-baseline justify-between sm:flex-row">
            <a
              className="mb-3 w-full py-2 pl-0 text-center text-sm text-primary sm:mb-0 sm:w-fit"
              href="https://rightsnow.fr/support/"
              target="_blank"
              rel="noreferrer"
            >
              Besoin d'aide ?
            </a>
            <button
              className="button w-full py-2 text-sm sm:w-fit"
              onClick={async () => {
                const res = await api.createCustomerPortalSession();
                window.location.href = (res as any).data;
              }}
            >
              <IconSubscription className="mr-2 hidden !w-4 sm:inline-flex" />
              Gérer mon abonnement sur Stripe
            </button>
          </div>
          <hr className="my-12 border-gray-300" />
          <div className="mx-auto text-center">
            <span className="text-xs opacity-70">En partenariat avec</span>
            <StripeLogo className="mx-auto w-14 fill-[#635bff]" />
          </div>
        </main>
      </div>
    </>
  );
}
