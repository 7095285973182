import React from 'react';
import { useQuery } from 'react-query';
import api from 'services/api';
import { useNavigate } from 'react-router-dom';
import IconLoading from '../../components/icons/IconLoading';
import { useAuth } from 'hooks/use-auth';
import IconPayment from '../../components/icons/IconPayment';
import useSubscriptionStatus from '../../hooks/use-subscription-status';
import GenericError from '../../components/GenericError';
import Loader from '../../components/Loader';

export default function PastDueContent() {
  const navigate = useNavigate();
  const { subscriptionStatus, isLoading, isError } = useSubscriptionStatus();
  const { signout } = useAuth();
  const handleSignout = () => {
    signout();
    navigate('/login');
  };

  if (isLoading) {
    return (
      <div className="flex h-56 items-center justify-center text-sm leading-none">
        <Loader type="vertical" />
      </div>
    );
  }

  if (isError) {
    return <GenericError />;
  }

  if (
    subscriptionStatus === undefined ||
    !(subscriptionStatus.status === 'past_due' || subscriptionStatus.status === 'unpaid')
  ) {
    navigate('/');
  }

  return (
    <>
      <h1 className="mb-6 flex text-xl text-red">
        <IconPayment className="mr-2 w-6 flex-shrink-0" />
        <strong>Échec du paiement</strong>
      </h1>
      <p>
        Pour pouvoir accéder à votre compte, merci de mettre à jour{' '}
        <a
          onClick={async () => {
            const res = await api.createCustomerPortalSession();
            window.location.href = (res as any).data;
          }}
          className="underline"
        >
          votre moyen de paiement
        </a>
        .
      </p>
      <div className="mt-6 flex flex-col justify-between sm:flex-row">
        <button
          className="button w-full py-2 text-sm sm:w-fit"
          onClick={async () => {
            const res = await api.createCustomerPortalSession();
            window.location.href = (res as any).data;
          }}
        >
          Mettre à jour mon moyen de paiement
        </button>
        <button
          className="button ghost mt-3 w-full border-primary py-2 text-sm text-primary sm:mt-0 sm:w-fit"
          onClick={handleSignout}
        >
          Se déconnecter
        </button>
      </div>
    </>
  );
}
