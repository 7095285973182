import React, { useRef, useState } from 'react';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import IconDelete from 'components/icons/IconDelete';
import GenericError from 'components/GenericError';
import api from 'services/api';
import Modal from 'components/Modal';
import { Dialog } from '@headlessui/react';
import { useAuth } from 'hooks/use-auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import TheModal, { TheModalProvider } from '../../components/TheModal';

export default function DeleteMyAccount() {
  const [error, setError] = useState(false);
  const { signout } = useAuth();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const cancelDeleteButtonRef = useRef(null);

  const [deleteInput, setDeleteInput] = useState('');
  const deleteMyAccount = async () => {
    setProcessing(true);
    try {
      await api.deleteUser();
      setProcessing(false);
      signout();
      navigate('/login');
    } catch (e) {
      setError(!!e);
    }
  };

  return (
    <>
      <details className="accordion mt-6 flex overflow-hidden rounded bg-red-700 text-white">
        <summary className="flex cursor-pointer p-6 hover:bg-red-800">
          <FontAwesomeIcon
            icon={icon({ name: 'diamond-exclamation', family: 'sharp', style: 'light' })}
            className="mr-2 h-6 flex-shrink-0"
          />
          <strong>Supprimer mon compte ?</strong>
        </summary>
        <div className="bg-red p-6 text-white">
          <p>
            <strong>ATTENTION</strong> ! Cette action est IRREVERSIBLE.
          </p>
          <ul className="mb-6 ml-6 list-disc">
            <li>vos données personnelles seront entièrement supprimées</li>
            <li>la totalité des historiques de vos titres, diffusions, avances Sacem seront supprimés</li>
            <li>les factures liées à votre abonnement seront inaccessibles, pensez à les télécharger le cas échéant</li>
          </ul>
          <TheModalProvider>
            <TheModal.Trigger className="rounded border border-white px-4 py-2 text-sm hover:bg-white hover:text-red">
              <FontAwesomeIcon
                icon={icon({ name: 'trash-can', family: 'sharp', style: 'light' })}
                className="mr-2 inline-flex h-4 align-sub"
              />
              Supprimer mon compte
            </TheModal.Trigger>
            <TheModal icon="exclamation" color="red" escClose={true}>
              <TheModal.Title>Supprimer mon compte ?</TheModal.Title>
              <TheModal.Body>
                {!error && (
                  <>
                    <p>
                      <strong>La suppression de votre compte entrainera</strong> :
                    </p>
                    <ul className="mb-6 ml-6 list-disc">
                      <li>
                        la suppression <strong className="text-red">DEFINITIVE</strong> de vos données personnelles
                      </li>
                      <li>
                        la destruction <strong className="text-red">TOTALE</strong> de l'historique de diffusion de tous
                        vos titres
                      </li>
                      <li>l'impossibilité d'accéder aux factures liées à votre abonnement</li>
                    </ul>
                    <p>
                      <em>
                        Entrez <strong className="bg-yellow-200">DELETE</strong> en majuscule dans le champ ci-dessous
                        pour activer le bouton le suppression
                      </em>
                    </p>
                    <input
                      className="mt-2 rounded border p-2"
                      type="text"
                      value={deleteInput}
                      onChange={(e) => setDeleteInput(e.target.value)}
                    />
                  </>
                )}
                {error && <GenericError />}
              </TheModal.Body>
              <TheModal.Footer>
                {deleteInput === 'DELETE' && (
                  <TheModal.Button.Confirmation action={deleteMyAccount} disabled={processing} theme="danger">
                    Supprimer
                  </TheModal.Button.Confirmation>
                )}
                <TheModal.Button.Cancel theme="neutral">Ne pas supprimer mon compte</TheModal.Button.Cancel>
              </TheModal.Footer>
            </TheModal>
          </TheModalProvider>
        </div>
      </details>
    </>
  );
}
