import { useQuery } from 'react-query';
import { UserInfoDTO } from 'shared-types';
import api from 'services/api';

const defaultUserInfo: UserInfoDTO = {
  registerTs: 0,
  userName: '',
  email: '',
  trackCount: 0,
  unreadNotif: 0,
  sacemMandateStatus: 'PENDING',
};

export default function useUserInfo(initialData?: UserInfoDTO) {
  const { data, isLoading, isError } = useQuery(
    '/api/user-info',
    () => api.fetchMyInfo(),
    { initialData, refetchInterval: 30000 },
  );
  return {
    userInfo: data || defaultUserInfo,
    isLoading,
    isError,
  };
}
