import {
  faClock,
  faMoneyBillTransfer,
  faCheck,
  faCircleExclamation,
  IconDefinition,
} from '@fortawesome/sharp-light-svg-icons';
import { CashOutStatus } from 'shared-types/cash-out';

export const cashOutStatusDetails: {
  id: number;
  status: CashOutStatus;
  name: string;
  description: string;
  icon: IconDefinition;
  color: string;
}[] = [
  {
    id: 1,
    status: 'AWAITING_SACEM_VALIDATION',
    name: 'Attente de validation Sacem',
    description:
      "La demande d'avance a été initiée, nous attendons la confirmation de la Sacem. Cette étape peut prendre plusieurs jours, nous sommes tributaires de la Sacem.",
    icon: faClock,
    color: 'yellow',
  },
  {
    id: 2,
    status: 'ONGOING_TRANSFER',
    name: 'Virement en cours',
    description:
      "Le virement vers votre compte bancaire a été initié et devrait être effectif d'ici 48 à 72h ouvrées (Selon les établissements bancaires, ce délai peut être prolongé).",
    icon: faMoneyBillTransfer,
    color: 'blue',
  },
  {
    id: 3,
    status: 'COMPLETED',
    name: 'Terminé',
    description:
      "Votre compte a été crédité du montant demandé. Si vous n'avez pas reçu le virement 48h ouvrées après l'affichage de ce statut, merci de nous contacter.",
    icon: faCheck,
    color: 'green',
  },
  {
    id: 99,
    status: 'ERROR',
    name: 'Problème sur la demande',
    description:
      'Cliquez sur le lien "Pourquoi ?" de la demande en question pour obtenir plus de détails. Généralement cela est dû à un problème d\'informations communiquées à la Sacem ou une demande de documents complémentaires.',
    icon: faCircleExclamation,
    color: 'red',
  },
];
