import PageTitle from 'features/layout/PageTitle';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import api from 'services/api';
import { UpdateUserDTO } from 'shared-types';
import PersonnalDataForm, { PersonnalDataFormValues } from './PersonnalDataForm';
import BankDataForm, { BankDataFormValues } from 'features/settings/BankDataForm'

const BankPage = () => {
  const { data: userDTO } = useQuery('/api/personnal_data', () => api.fetchPersonalData());
  const [updatedData, setUpdatedData] = useState<UpdateUserDTO | undefined>();
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateError, setUpdateError] = useState(false);
  const updatePersonnalData = async (values: BankDataFormValues) => {
    if (userDTO) {
      const data: UpdateUserDTO = {
        firstName: userDTO.firstName,
        lastName: userDTO.lastName,
        phone: userDTO.phone,
        addressLine1: userDTO.addressLine1,
        coad: userDTO.coad,
        iban: values.iban,
      };
      try {
        setUpdateError(false);
        const updated = await api.updatePersonalData(data);
        setUpdatedData(updated);
        setUpdateSuccess(true);
        window.scrollTo(0, 0);
      } catch (error) {
        setUpdateError(true);
      }
    }
  };
  return (
    <>
      <PageTitle title="Informations bancaires" />
      <BankDataForm
        initialData={updatedData || userDTO || {}}
        handleSubmit={updatePersonnalData}
        updateSuccess={updateSuccess}
        updateError={updateError}
        closeUpdateSuccessMessage={() => setUpdateSuccess(false)}
      />
    </>
  );
};

export default BankPage
