import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import api from '../../services/api';
import { CardRightsSummary } from './CardRightsSummary';
import {
  availableCashOutRequests,
  calculateUserStatusId,
  CashOutRequest,
  statusOrder,
  userStatus,
} from '../../shared-types/user-status';
import Alert from '../../components/Alert';
import useAvailableCashOutAmount from 'hooks/use-available-cash-out-amount';
import { UserDTO } from 'shared-types';
import useRights from 'hooks/use-rights';
import EmptyState from '../../components/EmptyState';
import { ErrorState, isLoadingFake } from 'App';
import useCashOut from 'hooks/use-cash-out';
import useUserInfo from 'hooks/use-user-info';

// Day's date
const currentDate: Date = new Date();
const day: number = currentDate.getDate();
const month: number = currentDate.getMonth() + 1;
const year: number = currentDate.getFullYear();
// Add a "0" if necessary
const formattedDate: string = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;

const baseUrl = 'https://tally.so/embed/nWDEvN?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1';

const getIframeSrc = ({
  amount,
  user: { email, firstName, lastName, addressLine1, iban, id },
  userStatusId,
}: {
  amount: number;
  user: UserDTO;
  userStatusId: number;
}) => {
  return `${baseUrl}
  &amountRequested=${amount}
  &email=${email}
  &id=${id}
  &firstName=${firstName}
  &lastName=${lastName}
  &address=${addressLine1}
  &todayDate=${formattedDate}
  &iban=${iban}
  &userStatus=${userStatusId}
  `;
};

declare global {
  interface Window {
    Tally: {
      loadEmbeds: () => void;
    };
  }
}

export function CashOutRequestPage() {
  const [iframeSrc, setIframeSrc] = useState<string | null>();
  const { rights, isLoadingRights, isErrorRights } = useRights();
  const { amountToBeCashedOut, totalCashedOut, isLoadingAmountToBeCashedOut } = useAvailableCashOutAmount()
  const isCashOutPossible = rights && rights.total > 0 && totalCashedOut !== undefined && totalCashedOut === 0;
  const { cashOuts, isLoadingCashOuts } = useCashOut();
  const [userStatusId, setUserStatusId] = useState<number>(1);
  const { userInfo, isLoading: isLoadingUserInfo } = useUserInfo();
  const { data: user, isLoading: isLoadingUser } = useQuery({
    queryKey: '/api/personnal_data',
    queryFn: () => api.fetchPersonalData(),
    // onSuccess: (user) => {
    //   const isUserInfoComplete = user.addressLine1 && user.iban && birthDate;
    //   if (isUserInfoComplete) {
    //     setIframeSrc(getIframeSrc({ amount: selectedCashOutRequest.amount, user }));
    //   } else {
    //     setIframeSrc(null);
    //   }
    // },
  });


  useEffect(() => {
    const isLoading = isLoadingUser || isLoadingUserInfo || isLoadingCashOuts || isLoadingAmountToBeCashedOut;
    if (!isLoading && user) {
      setIframeSrc(getIframeSrc({
        amount: amountToBeCashedOut || 0,
        user: { ...user, addressLine1: user.addressLine1 || '' },
        userStatusId
      }));
    } else {
      setIframeSrc(null);
    }
  }, [isLoadingUser, isLoadingUserInfo, isLoadingCashOuts, isLoadingAmountToBeCashedOut]);

  const availableAdvance = Math.max(amountToBeCashedOut || 0);

  useEffect(() => {
    const widgetScriptSrc = 'https://tally.so/widgets/embed.js';

    const load = () => {
      // Load Tally embeds
      if (typeof window.Tally !== 'undefined') {
        window.Tally.loadEmbeds();
        return;
      }

      // Fallback if window.Tally is not available
      document.querySelectorAll('iframe[data-tally-src]:not([src])').forEach((iframeEl) => {
        if (iframeEl instanceof HTMLIFrameElement && iframeEl.dataset.tallySrc) {
          iframeEl.src = iframeEl.dataset.tallySrc;
        }
      });
    };

    // If Tally is already loaded, load the embeds
    if (typeof window.Tally !== 'undefined') {
      load();
      return;
    }

    // If the Tally widget script is not loaded yet, load it
    if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
      const script = document.createElement('script');
      script.src = widgetScriptSrc;
      script.onload = load;
      script.onerror = load;
      document.body.appendChild(script);
      return;
    }
  }, [])

  const renderErrorMessage = () => {
    if (!user) return null;
    if (!user.addressLine1) return 'Adresse non renseignée ou incomplète.';
    if (!user.iban) return 'IBAN non renseigné ou invalide.';
    return null;
  };

  const loadingState = isLoadingRights || isLoadingFake;
  const errorState = isErrorRights || ErrorState;

  if (loadingState) {
    return <div>Chargement...</div>;
  }

  if (errorState) {
    return <div>Erreur lors du chargement des données</div>;
  }

  return (
    <>
      {!isCashOutPossible ? (
        <>
          <EmptyState title="Un peu de patience">
            <p>...pour le moment, vous ne pouvez pas demander d'avance ou vous avez déjà une avance en cours ;)</p>
          </EmptyState>
        </>
      ) : (
        <>
          <h1 className="h4 text-gray-800">
            Demande d'avance
            {/*<span className="font-body font-normal">*/}
            {/*  &mdash; Statut de votre profil : {userStatus.find((status) => status.id === userStatusId)?.name}*/}
            {/*</span>*/}
          </h1>
          <div className="space-y-6">
            <CardRightsSummary />
            <Alert color="orange" title="IMPORTANT">
              Cette avance sera <strong>directement remboursée par la Sacem à RightsNow!</strong> grâce à votre(vos)
              prochaine(s) répartition(s). En aucun cas vous n'êtes redevable directement à RightsNow!.
            </Alert>
            {amountToBeCashedOut && amountToBeCashedOut < 10 ? (
              <div className="w-full overflow-hidden rounded bg-white p-6 shadow-xl">
                Pour pouvoir effectuer une demande, il faut que vous ayez au moins 10 € de droits générés depuis la dernière répartition Sacem.
              </div>
            ) : (
              iframeSrc && (
                <div className="rounded bg-white px-6 shadow">
                  <iframe
                    key={iframeSrc || 'default-key'}
                    src={iframeSrc || undefined} // Remplace null par undefined
                    loading="lazy"
                    width="100%"
                    height="984"
                    frameBorder={0}
                    marginHeight={0}
                    marginWidth={0}
                    className="mt-4"
                  />
                </div>
              )
            )}
          </div>
        </>
      )}
    </>
  );
}
