import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

interface CountUpProps {
  value: number;
  duration?: number;
  type?: 'currency' | 'int';
  className?: string;
}

export const CountUp: React.FC<CountUpProps> = ({ value, duration = 250, type = 'int', className }) => {
  const [count, setCount] = useState(0);
  const start = useRef(0);

  useEffect(() => {
    const startTimestamp = performance.now();

    const step = (timestamp: number) => {
      const progress = timestamp - startTimestamp;
      const newValue = Math.min(progress / duration, 1) * value;
      setCount(newValue);

      if (progress < duration) {
        requestAnimationFrame(step);
      } else {
        setCount(value);
      }
    };

    requestAnimationFrame(step);
  }, [value, duration]);

  const formattedCount = type === 'currency' ? `${count.toFixed(0)} €` : count.toFixed(0);

  const combinedClassName = classNames(className);

  return <strong className={combinedClassName}>{formattedCount}</strong>;
};
