import React from 'react';
import PageTitle from '../layout/PageTitle';
import { RightsHistoricPage } from './RightsHistoricPage';

const RightsHistoric = () => {
  return (
    <>
      <PageTitle title="Monitoring des médias" />
      <div className="w-full max-w-3xl">
        <RightsHistoricPage />
      </div>
    </>
  );
};

export default RightsHistoric;
