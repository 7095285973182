import React from 'react';
import CTA from '../components/CTA';

const AllButtons: React.FC = () => {
  const variants = ['classic', 'outline', 'invert'] as const;
  const colors = ['primary', 'secondary', 'green', 'red'] as const;
  const sizes = ['xs', 'sm', 'md', 'lg', 'xl'] as const;

  return (
    <div className="flex">
      <div className="bg-white p-5">
        {variants.map((variant) => (
          <div key={variant}>
            <p className="text-xl font-bold">{variant.charAt(0).toUpperCase() + variant.slice(1)} Variant</p>
            {colors.map((color) => (
              <div key={color}>
                <p className="font-bold">{color.charAt(0).toUpperCase() + color.slice(1)} Color</p>
                <div className="space-x-4">
                  {sizes.map((size) => (
                    <CTA
                      key={`${variant}-${color}-${size}`}
                      href="https://www.domaine.com"
                      variant={variant}
                      color={color}
                      size={size}
                      className="capitalize"
                    >
                      {`${variant} ${color} ${size}`}
                    </CTA>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="bg-black p-5 text-white">
        {variants.map((variant) => (
          <div key={variant}>
            <p className="text-xl font-bold">{variant.charAt(0).toUpperCase() + variant.slice(1)} Variant</p>
            {colors.map((color) => (
              <div key={color}>
                <p className="font-bold">{color.charAt(0).toUpperCase() + color.slice(1)} Color</p>
                <div className="space-x-4">
                  {sizes.map((size) => (
                    <CTA
                      key={`${variant}-${color}-${size}`}
                      href="https://www.domaine.com"
                      variant={variant}
                      color={color}
                      size={size}
                      className="capitalize"
                    >
                      {`${variant} ${color} ${size}`}
                    </CTA>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllButtons;
