import React from 'react';
import { Field, Form, FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import cx from 'classnames';
import InputField from 'components/InputField';
import { UpdateUserDTO, UserDTO } from 'shared-types';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isValidIBAN } from 'ibantools';
import Alert from '../../components/Alert';
import TheModal, { TheModalProvider } from '../../components/TheModal';
import GenericError from 'components/GenericError';

const BankDataFormSchema = Yup.object()
  .shape({
    iban: Yup.string()
      .required('Ce champ est obligatoire')
      .test('IBAN', 'IBAN invalide', (value) => isValidIBAN(value || '')),
  })
  .defined();

export type BankDataFormValues = Yup.InferType<typeof BankDataFormSchema>;

type Props = {
  initialData: Partial<UserDTO>;
  handleSubmit: (values: Partial<UpdateUserDTO>) => void;
  updateSuccess?: boolean;
  updateError?: boolean;
  closeUpdateSuccessMessage: () => void;
};

class BankDataForm extends React.Component<Props & FormikProps<BankDataFormValues>> {
  private theAction: (() => void) | undefined;
  render() {
    const { updateSuccess, updateError, isSubmitting, closeUpdateSuccessMessage } = this.props;
    return (
      <Form className="space-y-6">
        {updateSuccess && (
          <div className="message mb-6 flex justify-between bg-green-500 text-white">
            <div className="content">Informations mises à jour.</div>
            <div className="cursor-pointer self-baseline" onClick={closeUpdateSuccessMessage}>
              <FontAwesomeIcon icon={duotone('xmark-circle')} className="h-5 w-5 align-sub" />
            </div>
          </div>
        )}
        <div className="rounded bg-white p-6 shadow-xl">
          <div>
            <Alert color="green" shadow="shadow-none" title="🔒 IMPORTANT">
              <p>
                Toutes les données bancaires sont transférées de manière cryptée et stockées sur des serveurs situés au
                sein de l'UE.
              </p>
              <p>
                <strong>Ces données sont uniquement destinées pour la réalisation de virement sur votre compte</strong>.
              </p>
            </Alert>
            <Field
              label="IBAN"
              name="iban"
              placeholder="Ex : FR7610096000302146262394A97"
              className="w-full"
              isRequired
              component={InputField}
            />
          </div>
          <div className="actions mt-4">
            <button
              type="submit"
              className={cx('button w-full md:w-auto', {
                loading: isSubmitting,
              })}
              disabled={isSubmitting}
            >
              Mettre à jour
            </button>
          </div>
          {updateError && <GenericError className="mt-4" />}
        </div>
      </Form>
    );
  }
}

export default withFormik<Props, BankDataFormValues>({
  enableReinitialize: true,
  mapPropsToValues: (props) => ({
    iban: props.initialData.iban || '',
  }),

  validationSchema: BankDataFormSchema,

  handleSubmit: (values, { props }) => props.handleSubmit(values),
})(BankDataForm);
