import React from 'react';
import { useAuth } from 'hooks/use-auth';
import useUserInfo from 'hooks/use-user-info';
import { Link, useNavigate } from 'react-router-dom';
import { ActiveLink } from 'components/ActiveLink';
import RightsNowLogo from '../../components/svg/RightsNowLogo';
import RightsNowLogoIcon from '../../components/svg/RightsNowLogoIcon';
import IconMenu from '../../components/icons/IconMenu';
import Tippy from '@tippyjs/react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Banner from '../../components/Banner';
import { useQueryClient } from 'react-query'

export default function NavigationHeader() {
  const {
    userInfo: { registerTs, userName, unreadNotif, trackCount, subscription, email, sacemMandateStatus },
  } = useUserInfo();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { signout } = useAuth();
  const handleSignout = () => {
    signout();
    queryClient.clear();
    navigate('/login');
  };

  const subscriptionStatus = subscription?.status;
  const registerDate = new Date(registerTs);

  // TODO : subscription not mandatory anymore
  //  handle its status differently
  if (subscriptionStatus === 'canceled') {
    navigate('/canceled-subscription');
  } else if (subscriptionStatus === 'past_due' || subscriptionStatus === 'unpaid') {
    navigate('/past-due');
  }

  return (
    <React.Fragment>
      <div className="relative mx-auto flex max-w-screen-xl items-center justify-between px-4 md:flex-row md:px-6 lg:px-8">
        <a href="/dashboard">
          <RightsNowLogo className="relative mr-4 w-40 py-4 sm:inline-block md:hidden lg:inline-block" />
        </a>
        <a href="/dashboard">
          <RightsNowLogoIcon className="relative mr-4 hidden w-8 py-4 sm:hidden md:inline-block lg:hidden" />
        </a>
        <input className="hidden" type="checkbox" id="hamburger" />
        <label className="m-0 ml-auto flex w-8 cursor-pointer items-center text-gray-700 md:hidden" htmlFor="hamburger">
          <IconMenu />
        </label>
        <div className="mainItems md:ml-3">
          <div className="flex flex-col justify-between space-y-2 transition-all md:mr-auto md:flex-row md:items-center md:space-x-2 md:space-y-0 md:py-4">
            <ActiveLink
              to="/dashboard"
              className="flex items-center rounded-lg px-3.5 py-2.5 text-base hover:bg-white/50 lg:text-base"
            >
              <FontAwesomeIcon
                icon={icon({ name: 'home-user', family: 'sharp', style: 'light' })}
                className="h-5 md:hidden lg:inline-block"
              />
              <span className="ml-2 sm:ml-0 lg:ml-2">Tableau de bord</span>
            </ActiveLink>
            <ActiveLink
              to="/my-tracks"
              className="flex items-center rounded-lg px-3.5 py-2.5 text-base hover:bg-white/50 lg:text-base"
            >
              <FontAwesomeIcon
                icon={icon({ name: 'list-music', family: 'sharp', style: 'light' })}
                className="h-5 md:hidden lg:inline-block"
              />
              <span className="ml-2 sm:ml-0 lg:ml-2">
                Mes titres
                {trackCount > 0 && <span className="counter">{trackCount}</span>}
              </span>
            </ActiveLink>
            <ActiveLink
              to="/cash-out-historic"
              className="flex items-center rounded-lg px-3.5 py-2.5 text-base hover:bg-white/50 lg:text-base"
            >
              <FontAwesomeIcon
                icon={icon({ name: 'hand-holding-dollar', family: 'sharp', style: 'light' })}
                className="h-5 md:hidden lg:inline-block"
              />
              <span className="ml-2 sm:ml-0 lg:ml-2">Mes avances</span>
            </ActiveLink>
          </div>
          <div className="mt-0 flex flex-col pt-4 md:m-0 md:flex-row md:items-center md:justify-between md:space-x-4 md:p-0 md:py-4">
            <Tippy content="Offres exclusives partenaires">
              <div id="offers">
                <ActiveLink
                  to="/settings/offers"
                  className="relative hidden h-10 w-10 cursor-pointer items-center justify-center rounded-full align-middle hover:bg-white hover:text-primary hover:shadow-sm md:flex"
                >
                  <FontAwesomeIcon
                    icon={icon({ name: 'gift-card', family: 'sharp', style: 'light' })}
                    className="h-5"
                  />
                </ActiveLink>
              </div>
            </Tippy>
            <Tippy content="Quoi de neuf sur RightsNow! ?">
              <div id="news">
                <a
                  href="https://rightsnow.notion.site/ed80a5f8fd1e4aabba817a7deb540fb4?v=2ff216346f2544f9af3b783f5584bfe9&pvs=4"
                  target="_blank"
                  className="relative hidden h-10 w-10 cursor-pointer items-center justify-center rounded-full align-middle hover:bg-white hover:text-primary hover:shadow-sm md:flex"
                  rel="noreferrer"
                >
                  <span className="absolute right-0.5 top-0.5 inline-block h-2 w-2 rounded-full bg-red-600"></span>
                  <FontAwesomeIcon icon={icon({ name: 'bell-on', family: 'sharp', style: 'light' })} className="h-5" />
                </a>
              </div>
            </Tippy>
            <div className="account">
              <div className="relative hidden md:m-0 md:flex md:p-0">
                <FontAwesomeIcon
                  icon={icon({ name: 'circle-user', family: 'sharp', style: 'light' })}
                  className="h-7 text-primary"
                />
                {unreadNotif > 0 && (
                  <div className="absolute -top-2 right-0 h-4 w-4 rounded-3xl bg-red text-center text-xs text-white">
                    {unreadNotif}
                  </div>
                )}
              </div>
              <div className="mt-0 flex items-baseline justify-between whitespace-nowrap border-t border-gray-300 pt-4 font-bold md:ml-2 md:inline-block md:border-0 md:pt-0 md:text-sm">
                <div
                  className="max-w-full overflow-hidden overflow-ellipsis text-gray-700 md:max-w-[180px]"
                  title={userName}
                >
                  <FontAwesomeIcon
                    icon={icon({ name: 'circle-user', family: 'sharp', style: 'light' })}
                    className="mr-1 inline-flex !h-5 align-sub text-primary md:hidden"
                  />
                  {userName}
                </div>
                {/*<div className="text-green items-center ml-2 sm:ml-0">*/}
                {/*  {perceivedRightsAmount} €*/}
                {/*</div>*/}
              </div>
              <div className="dropdown mt-4 w-full">
                {/* <Link to="/notifications" className="px-6 py-4 flex items-center">

                <IconNotification /> <span className="ml-5">
                  Notifications
                </span>
              </Link> */}
                {/*<Link to="/payouts" className={`md:px-6 py-4 flex items-center ${isSubscribed ? '' : 'disabled'}`}>*/}
                {/*    <IconPayment/> <span className="ml-5">Versements</span>*/}
                {/*</Link>*/}
                <Link
                  to="/channels-monitoring"
                  className="flex items-center rounded-lg px-3.5 py-2.5 md:rounded-none md:px-6 md:py-4"
                >
                  <FontAwesomeIcon
                    icon={icon({ name: 'monitor-waveform', family: 'sharp', style: 'light' })}
                    className="h-6"
                  />
                  <span className="ml-5">Monitoring</span>
                </Link>
                <Link
                  to="/settings/account"
                  className="flex items-center rounded-lg px-3.5 py-2.5 md:rounded-none md:px-6 md:py-4"
                >
                  <FontAwesomeIcon icon={icon({ name: 'gear', family: 'sharp', style: 'light' })} className="h-6" />
                  <span className="ml-5">Paramètres</span>
                </Link>
                <a
                  className="flex cursor-pointer items-center rounded-lg border-t border-gray-200 px-3.5 py-2.5 text-red hover:bg-red-50 hover:text-red-800 md:rounded-none md:px-6 md:py-4"
                  onClick={handleSignout}
                >
                  <FontAwesomeIcon
                    icon={icon({ name: 'right-from-bracket', family: 'sharp', style: 'light' })}
                    className="h-6"
                  />
                  <span className="ml-5">Déconnexion</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="relative max-w-screen-xl px-4 mx-auto md:mt-2 md:px-6 lg:px-8">*/}
      {/*  <div className="p-2 bg-yellow-200 text-xs shadow rounded text-center">*/}
      {/*    Vous souhaitez monitorer plus de 100 titres, votre compte est en cours de vérification. <a href="" target="_blank">En savoir plus</a>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*{ sacemMandateStatus === 'PENDING' && (*/}
      {/*  <Banner color="secondary" linkTo="/my-tracks/verification" linkLabel="Valider mes oeuvres">*/}
      {/*    <strong>Attention</strong>, la paternité des oeuvres déclarées à RightsNow! n'a pas été vérifiée. Les données*/}
      {/*    affichées peuvent être approximatives.*/}
      {/*  </Banner>*/}
      {/*)}*/}
      {sacemMandateStatus === 'ONGOING' && (
        <Banner color="yellow">
          <FontAwesomeIcon
            icon={icon({ name: 'circle-exclamation-check', family: 'sharp', style: 'light' })}
            className="mr-2 h-5 align-middle"
          />
          <strong>Vérification de la paternité des oeuvres en cours...</strong> Ce bandeau disparaitra une fois les
          titres validés par RightsNow!
        </Banner>
      )}
      {subscription?.status === 'trialing' && registerDate.getTime() < new Date('2023-12-22').getTime() && (
        <Banner color="yellow" linkTo="/settings/subscription" linkLabel="Mettre à jour mon abonnement">
          Voici la nouvelle version de RightsNow!. En tant qu'ancien membre, vous profitez d'un mois gratuit.
        </Banner>
      )}
      {subscription?.cancelAtPeriodEnd && (
        <Banner color="yellow" linkTo="/settings/subscription" linkLabel="Plus de détails">
          Vous n'aurez plus accès aux services de RightsNow! à partir du{' '}
          {moment(subscription?.cancelAtTs || 0).format('DD/MM/YYYY')}.
        </Banner>
      )}
    </React.Fragment>
  );
}
