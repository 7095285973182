import React, { ReactElement, useEffect, useRef, useState } from 'react';
import CreateAccountForm, { CreateAccountFormSchema, defaultValues } from './CreateAccountForm';
import Greeting from './Greeting';
import api from 'services/api';
import {
  CreateSubscriptionRequest,
  InvoicePaymentIntent,
  RegisterUserRequestDTO,
  SubscriptionPriceDTO,
} from 'shared-types';
import AddTrack, { TrackData } from './AddTrack';
import Payment from './Payment';
import { StripeData } from '../../components/CreditCardForm';
import { CardNumberElement } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import { ApiError, isWretcherError } from '../../utils/errors'
import { CustomEvent } from '@piwikpro/react-piwik-pro';
import { useQuery } from 'react-query';
import Loader from 'components/Loader';

export interface RegisterWizardProps {
  initialSource?: string;
}

export default function RegisterWizard({ initialSource }: RegisterWizardProps): ReactElement {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<StripeError | ApiError | unknown>(undefined);
  const [accountFormData, setAccountFormData] = useState<CreateAccountFormSchema>({
    ...defaultValues,
    source: initialSource || '',
  });
  const [trackData, setTrackData] = useState<TrackData>({
    selectedArtists: [],
    selectedTracks: [],
    selectedAlbums: [],
    uploadedTracks: [],
  });

  useEffect(() => {
    const scrollToTop = () => {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 20);
    };
    scrollToTop();
    CustomEvent.trackEvent('Register', 'Update Step', 'step', step);
  }, [step]);

  const subscribe = async (data: TrackData) => {
    setTrackData(data);
    setError(undefined);
    setLoading(true);
    try {
      const registerUser: RegisterUserRequestDTO = {
        user: accountFormData,
        music: {
          spotifyArtistIds: data.selectedArtists.map((t) => t.id),
          spotifyTrackIds: data.selectedTracks.map((t) => t.id),
          spotifyAlbumIds: data.selectedAlbums.map((a) => a.id),
          uploadedTracks: [],
        },
      };
      await api.register(registerUser);
      setLoading(false);
      fbq('track', 'Purchase');
      setStep(step + 1);
    } catch (error: any) {
      setError(error);
      setLoading(false);
    }
  };

  let StepComponent = <>{`undefined step ${step}`}</>;

  switch (step) {
    case 0:
      StepComponent = (
        <CreateAccountForm
          onSubmit={(data) => {
            setAccountFormData(data);
            fbq('track', 'Lead');
            setStep(step + 1);
          }}
          initialValues={accountFormData}
          disableSource={initialSource !== undefined}
        />
      );
      break;
    case 1:
      StepComponent = <AddTrack
        onPrevious={() => {
          setError(undefined);
          setStep(step - 1)
        }}
        onNext={subscribe}
        initialValues={trackData}
        disableNext={loading}
        error={error}
      />;
      break;
    case 2:
      StepComponent = <Greeting email={accountFormData?.email || ''} />;
      break;
    default:
      break;
  }

  // if (!isLoadingPrices && (isErrorPrices || prices.length === 0)) {
  //   return (
  //     <div className="rounded bg-red-100 p-6">
  //       <h4>Oups !</h4>
  //       <p className="text-red-700">
  //         Une erreur est survenue mais ce n'est pas de votre faute. <br /> Merci de réessayer dans quelques instants.
  //       </p>
  //     </div>
  //   );
  // }

  return <React.Fragment>{StepComponent}</React.Fragment>;
}
