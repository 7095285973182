import React from 'react';
import cx from 'classnames';
import { Broadcast, TimeRange, TimeRangeInterval } from 'shared-types';
import moment from 'moment';
import EmptyStateUpload from '../../components/svg/EmptyStateUpload';
import Recharts from '../../components/Recharts';
import LoaderChart from '../../components/LoaderChart';
import Squeleton from '../../components/Squeleton';

export interface BroadcastChartProps {
  noData?: boolean;
  trackId?: number;
}

function getLowerBound(timeRange?: TimeRange) {
  switch (timeRange) {
    case 'last_7_days':
      return moment().subtract(7, 'days');
    case 'last_30_days':
      return moment().subtract(30, 'days');
    case 'last_365_days':
      return moment().subtract(365, 'days');
    case 'all_time':
      return undefined;
    default:
      // last 7 days
      return moment().subtract(7, 'days');
  }
}

export function getTimeRangeInterval(timeRange?: TimeRange): TimeRangeInterval {
  const upperBound = moment();
  const lowerBound = getLowerBound(timeRange);

  return {
    lowerBound: lowerBound,
    upperBound: upperBound,
  };
}

export default function BroadcastChart({
  totalBroadcast,
  stats,
  from,
  to,
  customClass,
  trackCount,
  isLoading,
  isError,
}: {
  totalBroadcast: number;
  stats: Broadcast[];
  from?: number;
  to: number;
  customClass?: string;
  trackCount: number;
  isLoading?: boolean;
  isError?: boolean;
}) {
  const noTrack = !isLoading && !isError && trackCount === 0;
  const noData = !isLoading && !isError && totalBroadcast === 0;
  const noDataWithFilters = !isLoading && !noData && stats && stats.length === 0;

  const loadingMessage = isLoading && <Squeleton type="chart" bars={80} className="h-72" />;

  const noDataMessage = !noTrack && noData && (
    <div className="rounded bg-yellow-200 p-3 text-sm text-yellow-900">
      <p>Aucune donnée pour le moment...</p>
    </div>
  );

  const noDataWithFiltersMessage = noDataWithFilters && (
    <div className="rounded bg-yellow-200 p-3 text-sm text-yellow-900">
      <p>😭 Aucune donnée récoltée sur ce(s) média(s) durant cette période...</p>
    </div>
  );

  const noTrackMessage = noTrack && (
    <div className="my-6 rounded bg-primary-50 p-6 text-center">
      <EmptyStateUpload className="mx-auto mb-6 w-64" />
      <p>Pour commencer à récolter les diffusions,</p>
      <a href="/track-upload" className="button">
        Ajoutez votre premier titre
      </a>
    </div>
  );

  const errorMessage = !isLoading && isError && (
    <div className="rounded bg-red-100 p-3 text-sm text-red-900">
      <p className="text-red-700">Une erreur est survenue. Merci de réessayer dans quelques instants.</p>
    </div>
  );

  return (
    <React.Fragment>
      {/*{isLoading && <LoaderFilters className="mb-6 lg:!w-2/3 mx-auto" />}*/}
      {/*{!isLoading && !isError && totalFromOverview.totalBroadcast > 0 && (
        <>
          <div className="flex flex-wrap space-x-2 items-center mb-6 mx-auto md:justify-center">
            <span>Afficher les diffusions provenant de</span>
            <Select
              onChange={setChannelName}
              className="react-select"
              theme={customTheme}
              classNames={{ control: getControlClassNames }}
              options={channelNameOptions}
              isSearchable={true}
              value={channelName}
            />
            <FontAwesomeIcon icon={duotone('arrow-right')} className="h-5 text-gray-600" />
            <span>
              Total : <strong>{totalBroadcast.toLocaleString()}</strong>
            </span>
          </div>
        </>
      )}*/}
      {noTrackMessage} {noDataMessage} {noDataWithFiltersMessage} {loadingMessage} {errorMessage}
      <div
        className={cx('chart h-72 w-full overflow-hidden', customClass, {
          hidden: noData || isLoading || isError,
        })}
      >
        {!isLoading && !isError && stats && stats.length > 0 && <Recharts data={stats || []} from={from} to={to} />}
      </div>
    </React.Fragment>
  );
}
