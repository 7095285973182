import PageTitle from 'features/layout/PageTitle';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import api from 'services/api';
import { UpdateUserDTO } from 'shared-types';
import PersonnalDataForm, { PersonnalDataFormValues } from './PersonnalDataForm';
import DeleteMyAccount from './DeleteMyAccount';
import TheModal, { TheModalProvider } from '../../components/TheModal';
import { Description, Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import IconDelete from '../../components/icons/IconDelete';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AccountPage = () => {
  const { data } = useQuery('/api/personnal_data', () => api.fetchPersonalData());
  const [updatedData, setUpdatedData] = useState<UpdateUserDTO | undefined>();
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateError, setUpdateError] = useState(false);
  const updatePersonnalData = async (values: PersonnalDataFormValues) => {
    const data: UpdateUserDTO = {
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      addressLine1: values.addressLine1,
      coad: values.coad,
    };
    try {
      setUpdateError(false);
      const updated = await api.updatePersonalData(data);
      setUpdatedData(updated);
      setUpdateSuccess(true);
      window.scrollTo(0, 0);
    } catch (error) {
      setUpdateError(true);
    }
  };

  let [isOpen, setIsOpen] = useState(false);

  const theAction = () => {
    console.log('Action performed');
  };

  return (
    <>
      <PageTitle title="Informations personnelles" />
      <PersonnalDataForm
        initialData={updatedData || data || {}}
        handleSubmit={updatePersonnalData}
        updateSuccess={updateSuccess}
        updateError={updateError}
        closeUpdateSuccessMessage={() => setUpdateSuccess(false)}
      />
      <DeleteMyAccount />
    </>
  );
};

export default AccountPage;
