import React from 'react';
import PageTitle from '../layout/PageTitle';
import { CashOutRequestPage } from './CashOutRequestPage'

const CashOutRequest = () => {
  return (
    <>
      <PageTitle title="Monitoring des médias" />
      <div className="w-full">
        <CashOutRequestPage />
      </div>
    </>
  );
};

export default CashOutRequest;
