// tsFormatter.ts
import { useEffect, useRef } from 'react';
import dayjs from 'dayjs';

export function formatElapsedTime(startTs: dayjs.Dayjs) {
  const now = dayjs();
  const diff = dayjs.duration(now.diff(startTs));

  const years = diff.years();
  const months = Math.floor(diff.asMonths()) % 12;
  const days = diff.days();
  const hours = diff.hours();
  const minutes = diff.minutes();
  const seconds = diff.seconds();

  let parts: string[] = [];

  if (years > 0) parts.push(`${years} an${years > 1 ? 's' : ''}`);
  if (months > 0) parts.push(`${months} mois`);
  if (days > 0) parts.push(`${days} jour${days > 1 ? 's' : ''}`);
  if (hours > 0) parts.push(`${hours} h`);
  if (minutes > 0) parts.push(`${minutes} min`);
  if (seconds > 0 || parts.length === 0) parts.push(`${seconds} sec`);

  return `${parts.join(', ')}`;
}

export function getTimeUntilNextUpdate(): string {
  const now = dayjs();
  let nextUpdate = dayjs().hour(9).minute(0).second(0);
  if (now.isAfter(nextUpdate)) {
    nextUpdate = nextUpdate.add(1, 'day');
  }

  const diff = dayjs.duration(nextUpdate.diff(now));

  const hours = diff.hours();
  const minutes = diff.minutes();
  const seconds = diff.seconds();

  let parts: string[] = [];

  if (hours > 0) parts.push(`${hours} h`);
  if (minutes > 0) parts.push(`${minutes} min`);
  if (seconds > 0 || parts.length === 0) parts.push(`${seconds} sec`);

  return parts.join(', ');
}

export function updateTimes(
  registerTs: dayjs.Dayjs,
  elapsedTimeRef: React.RefObject<HTMLSpanElement>,
  dailyUpdateRef: React.RefObject<HTMLSpanElement>,
) {
  if (elapsedTimeRef.current) {
    elapsedTimeRef.current.textContent = formatElapsedTime(registerTs);
  }
  if (dailyUpdateRef.current) {
    dailyUpdateRef.current.textContent = getTimeUntilNextUpdate();
  }
}

export function useTimeUpdates(registerTs: number) {
  const elapsedTimeRef = useRef<HTMLSpanElement>(null);
  const dailyUpdateRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const intervalId = setInterval(() => updateTimes(dayjs(registerTs), elapsedTimeRef, dailyUpdateRef), 1000);
    return () => clearInterval(intervalId);
  }, [registerTs]);

  return { elapsedTimeRef, dailyUpdateRef };
}

export function useElapsedTime(registerTs: number) {
  const startDate = dayjs(registerTs);
  const daysElapsed = dayjs().diff(startDate, 'day');

  const getPreviousDays = (numDays: number) => {
    const daysArray = [];
    for (let i = 0; i < numDays; i++) {
      daysArray.push(dayjs().subtract(i + 1, 'day'));
    }
    return daysArray;
  };

  return { startDate, daysElapsed, getPreviousDays };
}

export function isUpdateInProgress(): boolean {
  const now = dayjs();
  const currentHour = now.hour();
  return currentHour >= 1 && currentHour < 9;
}
