import { useQuery } from 'react-query';
import api from 'services/api';

export default function useCashOut() {
  const {
    data: cashOuts,
    isLoading: isLoadingCashOuts,
    isError: isErrorCashOuts,
    ...rest
  } = useQuery(['/api/cash-outs'], () => api.fetchCashOuts(), { staleTime: Infinity });

  return {
    cashOuts: cashOuts,
    isLoadingCashOuts,
    isErrorCashOuts,
    ...rest,
  };
}
