// CardRightsTotal.tsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import 'dayjs/locale/fr';
import useUserInfo from '../../hooks/use-user-info';
import { Link } from 'react-router-dom';
import { CountUp } from '../../utils/countUp';
import { isUpdateInProgress, useElapsedTime, useTimeUpdates } from '../../utils/tsFormatter';
import { isLoadingFake } from '../../App';
import Squelton from '../../components/Squeleton';
import useRights, { useMonthlyRights } from 'hooks/use-rights';
import dayjs, { Dayjs } from 'dayjs';

export function CardRightsTotal() {
  const {
    userInfo: { registerTs },
  } = useUserInfo();

  const { rights, isLoadingRights } = useRights();
  const { monthlyRights, isLoadingMonthlyRights } = useMonthlyRights();

  const { startDate, daysElapsed, getPreviousDays } = useElapsedTime(registerTs);
  const previousDays = getPreviousDays(4);

  const getRights = (day: Dayjs) => (monthlyRights || []).find((r) => dayjs(r.date).isSame(day, 'day'));

  const { dailyUpdateRef } = useTimeUpdates(registerTs);

  return (
    <div className="flex flex-col space-y-6 rounded bg-white p-6 shadow">
      <div className="flex justify-between gap-x-6">
        <div className="flex min-w-0 gap-x-4">
          <FontAwesomeIcon
            icon={icon({ name: 'chart-mixed-up-circle-dollar', family: 'sharp', style: 'light' })}
            className="h-12 w-12 flex-none"
          />
          <div className="min-w-0 flex-auto">
            <p className="!pb-0 font-bold leading-6 text-gray-900">
              Droits générés sur {daysElapsed > 1 ? `les ${daysElapsed}` : 'le'} dernier{daysElapsed > 1 ? 's' : ''}{' '}
              jour{daysElapsed > 1 ? 's' : ''}
            </p>
            <p className="truncate text-xs leading-5 text-gray-500">
              Début de monitoring le {startDate.format('D MMMM YYYY')}&nbsp;&nbsp;&middot;&nbsp;&nbsp;
              {isUpdateInProgress() ? (
                'Mise à jour en cours...'
              ) : (
                <>
                  Mise à jour dans <span ref={dailyUpdateRef}></span>
                </>
              )}
            </p>
          </div>
        </div>
        <div className="shrink-0 sm:flex sm:flex-col sm:items-end">
          {!isLoadingRights ? <CountUp value={rights?.total || 0} type="currency" /> : <Squelton />}
        </div>
      </div>
      <div className="flow-root">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th className="whitespace-nowrap py-2 pr-3 text-left text-sm">Date</th>
                  <th className="whitespace-nowrap py-2 pl-3 text-right text-sm">Montant</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {previousDays.map((day, index) => {
                  const rightsForTheDay = getRights(day);
                  return (
                    <tr key={index}>
                      <td className="whitespace-nowrap py-2 text-sm leading-6">{day.format('DD/MM/YYYY')}</td>
                      <td className="whitespace-nowrap py-2 text-right text-sm text-green">
                        {!isLoadingMonthlyRights ? (
                          <>
                            +<CountUp value={rightsForTheDay?.amount || 0} /> €
                          </>
                        ) : (
                          <Squelton color="green" />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Link to="/rights-historic" className="inline-flex items-center self-end pt-2 text-sm leading-5">
        Plus de détails
        <FontAwesomeIcon
          icon={icon({ name: 'arrow-right', family: 'sharp', style: 'light' })}
          className="ml-1.5 h-3 w-3 flex-none align-middle"
        />
      </Link>
    </div>
  );
}
