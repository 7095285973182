import React from 'react';
import api from 'services/api';
import PageTitle from '../layout/PageTitle';
import IconAdd from '../../components/icons/IconAdd';
import EmptyStateUpload from '../../components/svg/EmptyStateUpload';
import GenericError from 'components/GenericError';
import Loader from 'components/Loader';
import { useQuery } from 'react-query';
import { MyTracksTableContainer } from 'features/tracks/MyTracksTableContainer';

const MyTracks = () => {
  const {
    data: trackCount,
    isLoading,
    isError,
  } = useQuery('/api/track-count', () => api.fetchTrackCount(), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: (query) => {
      return query.state.data === undefined || query.state.data === 0;
    },
    refetchOnReconnect: false,
  });

  return (
    <>
      <PageTitle title="Mes titres" />
      <div className="w-full">
        {!isLoading && isError && <GenericError className="shadow" />}
        {isLoading && !isError && <Loader className="justify-center" />}
        {!isLoading && !isError && trackCount !== undefined && trackCount === 0 && (
          <div className="flex w-full justify-center">
            <div className="w-full rounded bg-white p-6 text-center shadow-xl md:w-6/12">
              <h4 className="mb-6">C'est un peu vide par ici...</h4>
              <EmptyStateUpload className="mx-auto mb-6 w-64" />
              <p>C'est ici que vous pourrez suivre les statistiques de tous vos titres par canal et média.</p>
              <a className="button mt-6 w-full md:text-xl" href="/track-upload">
                <IconAdd className="mr-4" /> Mettre en ligne mon premier titre
              </a>
            </div>
          </div>
        )}
        {!isLoading && !isError && trackCount != undefined && trackCount > 0 && <MyTracksTableContainer />}
      </div>
    </>
  );
};

export default MyTracks;
