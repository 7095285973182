import React, { useState, useContext, createContext, ReactNode } from 'react';
import { Description, Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faDiamondExclamation, faQuestionCircle } from '@fortawesome/sharp-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const iconMap = {
  question: faQuestionCircle,
  exclamation: faDiamondExclamation,
  bullhorn: faBullhorn,
};

interface TheModalProps {
  color?: 'gray' | 'green' | 'red' | 'yellow';
  icon?: 'question' | 'exclamation' | 'bullhorn';
  escClose?: boolean;
  children: ReactNode;
}

interface TheModalContextProps {
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
}

const TheModalContext = createContext<TheModalContextProps | undefined>(undefined);

const TheModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return <TheModalContext.Provider value={{ isOpen, setIsOpen }}>{children}</TheModalContext.Provider>;
};

const TheModal: React.FC<TheModalProps> & {
  Trigger: React.FC<{ children: ReactNode; className?: string }>;
  Title: React.FC<{ children: ReactNode }>;
  Body: React.FC<{ children: ReactNode }>;
  Footer: React.FC<{ children: ReactNode }>;
  Button: {
    Confirmation: React.FC<{
      action?: () => void;
      children: ReactNode;
      disabled: boolean;
      theme?: 'primary' | 'neutral' | 'danger';
    }>;
    Cancel: React.FC<{
      children: ReactNode;
      theme?: 'primary' | 'neutral' | 'danger';
    }>;
  };
} = ({ color = 'gray', icon = 'question', escClose = true, children }) => {
  const context = useContext(TheModalContext);

  if (!context) {
    throw new Error('TheModal must be used within a TheModalProvider');
  }

  const { isOpen, setIsOpen } = context;

  const iconColorMap = {
    gray: 'text-gray-700 bg-gray-100',
    green: 'text-green-700 bg-green-100',
    red: 'text-red-700 bg-red-100',
    yellow: 'text-yellow-700 bg-yellow-100',
  };

  return (
    <Transition show={isOpen}>
      <Dialog onClose={() => escClose && setIsOpen(false)} className="relative z-50">
        <TransitionChild
          enter="ease-out duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/40" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              enter="ease-out duration-100"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="relative inline-block w-full transform overflow-hidden rounded bg-white text-left align-bottom opacity-100 shadow-xl transition-all sm:my-8 sm:max-w-4xl sm:scale-100 sm:align-middle">
                <div className={`bg-white px-4 py-4 sm:flex sm:items-start sm:p-6`}>
                  <div
                    className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${iconColorMap[color]} sm:mx-0 sm:h-10 sm:w-10`}
                  >
                    <FontAwesomeIcon icon={iconMap[icon] as IconProp} className={`h-4`} />
                  </div>
                  <div className="mt-3 w-full text-left sm:ml-4 sm:mt-0">{children}</div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

TheModal.Trigger = ({ children, className }) => {
  const context = useContext(TheModalContext);
  if (!context) {
    throw new Error('TheModal.Trigger must be used within a TheModalProvider');
  }
  return (
    <button className={className} onClick={() => context.setIsOpen(true)}>
      {children}
    </button>
  );
};

TheModal.Title = ({ children }) => {
  return (
    <DialogTitle as="h3" className="mb-0 mt-2 text-center text-lg font-medium leading-6 text-gray-900 md:text-left">
      {children}
    </DialogTitle>
  );
};

TheModal.Body = ({ children }) => {
  return (
    <Description as="div" className="mt-6">
      {children}
    </Description>
  );
};

TheModal.Footer = ({ children }) => {
  return <div className="mt-6 bg-gray-100 p-6 sm:-mb-6 sm:-ml-20 sm:-mr-6 sm:flex sm:flex-row-reverse">{children}</div>;
};

const buttonClassMap = {
  primary:
    '[&:only-of-type]:mt-0 last:mt-3 sm:last:mt-0 inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-white shadow-sm transition hover:bg-primary-700 focus:outline-none focus:ring-4 focus:ring-primary/10 sm:ml-3 sm:w-auto',
  neutral:
    '[&:only-of-type]:mt-0 last:mt-3 sm:last:mt-0 inline-flex w-full justify-center rounded-md border border-gray-700 g-transparent px-4 py-2 text-base transition hover:bg-white focus:outline-none focus:ring-4 focus:ring-gray-500/30 sm:ml-3 sm:mt-0 sm:w-auto',
  danger:
    '[&:only-of-type]:mt-0 last:mt-3 sm:last:mt-0 inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-white shadow-sm transition hover:bg-red-700 focus:outline-none focus:ring-4 focus:ring-red-500/30 sm:ml-3 sm:w-auto',
  confirmation:
    '[&:only-of-type]:mt-0 last:mt-3 sm:last:mt-0 inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-white shadow-sm transition hover:bg-green-700 focus:outline-none focus:ring-4 focus:ring-green-500/30 sm:ml-3 sm:w-auto',
};

TheModal.Button = {
  Confirmation: ({ action, children, theme = 'primary' }) => {
    const context = useContext(TheModalContext);
    if (!context) {
      throw new Error('TheModal.Button.Confirmation must be used within a TheModalProvider');
    }
    return (
      <button
        className={buttonClassMap[theme]}
        onClick={() => {
          action && action();
          context.setIsOpen(false);
        }}
      >
        {children}
      </button>
    );
  },
  Cancel: ({ children, theme = 'neutral' }) => {
    const context = useContext(TheModalContext);
    if (!context) {
      throw new Error('TheModal.Button.Cancel must be used within a TheModalProvider');
    }
    return (
      <button className={buttonClassMap[theme]} onClick={() => context.setIsOpen(false)}>
        {children}
      </button>
    );
  },
};

export default TheModal;
export { TheModalProvider };
