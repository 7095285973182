import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import React from 'react';
import { formatBroadcastNumber } from 'utils/broadcastHelper';
import Tippy from '@tippyjs/react';
import RightAmountWithMargin from 'components/broadcast/RightsAmountWithMargin';
import Squeleton from '../Squeleton';
import Loader from '../Loader';

export function BroadcastContent(props: {
  broadcastNumber: number;
  isLoadingBroadcast: boolean;
  rightsAmount: number;
  rightsMargin?: number;
  isLoadingRights: boolean;
  isLoading?: boolean;
  showUpdateSubscriptionLink?: boolean;
}) {
  if (props.isLoading) {
    return <Squeleton type="paragraph" lines={5} height="sm" />;
  } else if (props.showUpdateSubscriptionLink) {
    return (
      <a
        href="/settings/subscription"
        className="flex h-full w-full items-center justify-center rounded py-10 text-center text-xs text-gray-600 underline hover:bg-primary-50"
      >
        <FontAwesomeIcon icon={icon({ name: 'stars', family: 'duotone', style: 'solid' })} className="mr-1.5 h-4" />
        Changer de formule
      </a>
    );
  } else {
    return (
      <>
        <div className="flex items-center justify-between gap-x-4 py-3">
          <dt className="text-gray-500">Diffusions</dt>
          <dd className="text-gray-700">
            <strong>
              {props.isLoadingBroadcast ? (
                <>
                  <Loader size="xs" label="" />
                </>
              ) : (
                formatBroadcastNumber(props.broadcastNumber)
              )}
            </strong>
          </dd>
        </div>
        {/*<div className="flex items-center justify-between gap-x-4 py-3">*/}
        {/*  <dt className="inline-flex text-gray-500">*/}
        {/*    Droits générés*/}
        {/*    <Tippy content="Découvrez comment est calculé ce montant">*/}
        {/*      <a*/}
        {/*        href="https://www.rightsnow.fr/algorithme"*/}
        {/*        target="_blank"*/}
        {/*        rel="noopener"*/}
        {/*        className="ml-1 inline-flex items-center"*/}
        {/*      >*/}
        {/*        <FontAwesomeIcon*/}
        {/*          className="ml-1 h-4"*/}
        {/*          icon={icon({ name: 'arrow-up-right', family: 'duotone', style: 'solid' })}*/}
        {/*        />*/}
        {/*      </a>*/}
        {/*    </Tippy>*/}
        {/*  </dt>*/}
        {/*  <dd className="flex items-start gap-x-2">*/}
        {/*    <strong>*/}
        {/*      {props.isLoadingRights ? (*/}
        {/*        <Loader size="xs" label="" />*/}
        {/*      ) : (*/}
        {/*        <RightAmountWithMargin*/}
        {/*          amount={props.rightsAmount}*/}
        {/*          compact={true}*/}
        {/*          weightPercentage={props.rightsMargin}*/}
        {/*        />*/}
        {/*      )}*/}
        {/*    </strong>*/}
        {/*  </dd>*/}
        {/*</div>*/}
      </>
    );
  }
}
