import React from 'react';

type AlertProps = {
  color: 'gray' | 'green' | 'red' | 'blue' | 'yellow' | 'orange';
  title?: string;
  shadow?: 'shadow' | 'shadow-xl' | 'shadow-none';
  children: React.ReactNode;
  className?: string;
};

const Alert: React.FC<AlertProps> = ({ color, title, shadow = 'shadow', children, className = '' }) => {
  let bgColor, textColor;

  switch (color) {
    case 'gray':
      bgColor = 'bg-gray-100';
      textColor = 'text-gray-800';
      break;
    case 'green':
      bgColor = 'bg-green-100';
      textColor = 'text-green-800';
      break;
    case 'red':
      bgColor = 'bg-red-100';
      textColor = 'text-red-800';
      break;
    case 'blue':
      bgColor = 'bg-blue-100';
      textColor = 'text-blue-800';
      break;
    case 'yellow':
      bgColor = 'bg-yellow-100';
      textColor = 'text-yellow-900';
      break;
    case 'orange':
      bgColor = 'bg-orange-100';
      textColor = 'text-orange-800';
      break;
    default:
      bgColor = 'bg-gray-100';
      textColor = 'text-gray-800';
  }

  return (
    <div className={`mb-4 flex flex-col p-6 ${textColor} rounded-lg ${bgColor} ${shadow} ${className}`} role="alert">
      {title && <p className="mb-2 font-display text-xl">{title}</p>}
      <div>{children}</div>
    </div>
  );
};

export default Alert;
