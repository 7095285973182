import cx from 'classnames'
import { FormikErrors } from 'formik'
import * as Yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { duotone, icon } from '@fortawesome/fontawesome-svg-core/import.macro'

type PASSWD_REQUIREMENTS_TYPE = {
  MIN_8_CHAR: string;
  AN_UPPERCASE: string;
  A_NUMBER: string;
  A_SPECIAL_CHAR: string;
};

export const PASSWD_REQUIREMENTS: PASSWD_REQUIREMENTS_TYPE = {
  MIN_8_CHAR: 'au moins 8 caractères',
  AN_UPPERCASE: 'une majuscule',
  A_NUMBER: 'un chiffre',
  A_SPECIAL_CHAR: 'un caractère spécial (Ex : @%#...)',
}

export const passwordRequirementSchema = Yup.string()
  .required('Ce champ est obligatoire')
  .min(8, PASSWD_REQUIREMENTS.MIN_8_CHAR)
  .matches(/[A-Z]/, PASSWD_REQUIREMENTS.AN_UPPERCASE)
  .matches(/\d/, PASSWD_REQUIREMENTS.A_NUMBER)
  .matches(/[-!"#$%&'()*+,./:;<=>?@^_`{|}~]/, PASSWD_REQUIREMENTS.A_SPECIAL_CHAR)

export function isReqFullfiledHelper(
  values: any,
  errors: any,
  formFieldName: string,
  reqName: string,
) {
  return !!(
    values[formFieldName] &&
    !(
      errors &&
      errors[formFieldName] &&
      errors[formFieldName].includes(reqName)
    )
  )
}

export type PasswordRequirementProps = {
  isMin8CharReqSatisfied?: boolean;
  isAnUppercaseReqSatisfied?: boolean;
  isANumberReqSatisfied?: boolean;
  isASpecialCharReqSatisfied?: boolean;
};

export function PasswordRequirement(props: PasswordRequirementProps) {
  const {
    isMin8CharReqSatisfied,
    isAnUppercaseReqSatisfied,
    isANumberReqSatisfied,
    isASpecialCharReqSatisfied,
  } = props
  const isAllReqSatisfied: boolean = !!(
    isMin8CharReqSatisfied &&
    isAnUppercaseReqSatisfied &&
    isANumberReqSatisfied &&
    isASpecialCharReqSatisfied
  )
  return (
    <div
      className={cx('message', {
        'bg-green-100': isAllReqSatisfied,
        'bg-blue-100': !isAllReqSatisfied,
      })}
    >
      <div className={cx('text-blue-800', {
        'text-green-600': isAllReqSatisfied,
        'text-blue-800': !isAllReqSatisfied,
      })}>
        {!isAllReqSatisfied && (
          <div>
            <p>
              <strong>Votre mot de passe doit contenir :</strong>
            </p>
            <ul className="flex flex-col items-start">
              <li className="inline-flex justify-center items-center">
                {isMin8CharReqSatisfied && (
                  <FontAwesomeIcon icon={icon({ name: 'check', family: 'sharp', style: 'light' })} className="mr-2 h-4 text-green flex-shrink-0" />
                )}
                {!isMin8CharReqSatisfied && (
                  <FontAwesomeIcon icon={icon({ name: 'xmark', family: 'sharp', style: 'light' })} className="mr-2 h-4 text-red flex-shrink-0" />
                )}
                {PASSWD_REQUIREMENTS.MIN_8_CHAR}
              </li>
              <li className="inline-flex justify-center items-center">
                {isANumberReqSatisfied && (
                  <FontAwesomeIcon icon={icon({ name: 'check', family: 'sharp', style: 'light' })} className="mr-2 h-4 text-green flex-shrink-0" />
                )}
                {!isANumberReqSatisfied && (
                  <FontAwesomeIcon icon={icon({ name: 'xmark', family: 'sharp', style: 'light' })} className="mr-2 h-4 text-red flex-shrink-0" />
                )}
                {PASSWD_REQUIREMENTS.A_NUMBER}
              </li>
              <li className="inline-flex justify-center items-center">
                {isAnUppercaseReqSatisfied && (
                  <FontAwesomeIcon icon={icon({ name: 'check', family: 'sharp', style: 'light' })} className="mr-2 h-4 text-green flex-shrink-0" />
                )}
                {!isAnUppercaseReqSatisfied && (
                  <FontAwesomeIcon icon={icon({ name: 'xmark', family: 'sharp', style: 'light' })} className="mr-2 h-4 text-red flex-shrink-0" />
                )}
                {PASSWD_REQUIREMENTS.AN_UPPERCASE}
              </li>
              <li className="inline-flex justify-center items-center">
                {isASpecialCharReqSatisfied && (
                  <FontAwesomeIcon icon={icon({ name: 'check', family: 'sharp', style: 'light' })} className="mr-2 h-4 text-green flex-shrink-0" />
                )}
                {!isASpecialCharReqSatisfied && (
                  <FontAwesomeIcon icon={icon({ name: 'xmark', family: 'sharp', style: 'light' })} className="mr-2 h-4 text-red flex-shrink-0" />
                )}
                {PASSWD_REQUIREMENTS.A_SPECIAL_CHAR}
              </li>
            </ul>
          </div>

        )}
        {isAllReqSatisfied && (
          <div className="flex text-sm text-green-900 self-baseline">
            <FontAwesomeIcon icon={duotone('check')} className="h-5" />
            <p className="ml-3"><strong>Yes ! Votre mot de passe est bien sécurisé</strong>, vous pouvez dormir sur vos deux oreilles.</p>
          </div>
        )}

      </div>
    </div>
  )

}

interface PasswordRequirementInFormProps<Values> {
  fieldName: string;
  errors: FormikErrors<Values>;
  values: Values;
}

export function PasswordRequirementInForm<T>({
                                               fieldName,
                                               values,
                                               errors,
                                             }: PasswordRequirementInFormProps<T>) {
  const isReqFullfiled = (req: string) =>
    isReqFullfiledHelper(values, errors, fieldName, req)
  return (
    <PasswordRequirement
      isMin8CharReqSatisfied={isReqFullfiled(PASSWD_REQUIREMENTS.MIN_8_CHAR)}
      isAnUppercaseReqSatisfied={isReqFullfiled(
        PASSWD_REQUIREMENTS.AN_UPPERCASE,
      )}
      isANumberReqSatisfied={isReqFullfiled(PASSWD_REQUIREMENTS.A_NUMBER)}
      isASpecialCharReqSatisfied={isReqFullfiled(
        PASSWD_REQUIREMENTS.A_SPECIAL_CHAR,
      )}
    />
  )
}
