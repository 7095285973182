import React, { useEffect, useRef, useState } from 'react';
import { TrackWithMonitoringData } from 'shared-types';
import BroadcastChart from 'features/dashboard/BroadcastChart';
import IconDelete from 'components/icons/IconDelete';
import api from 'services/api';
import IconEdit from '../../components/icons/IconEdit';
import Tippy from '@tippyjs/react';
import Modal from 'components/Modal';
import { Dialog } from '@headlessui/react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {duotone, icon} from '@fortawesome/fontawesome-svg-core/import.macro';
import { useQueryClient } from 'react-query';
import PageTitle from "../layout/PageTitle";
import BroadcastChartWithFilter from 'features/dashboard/BroadcastChartWithFilter'

interface Props {
  track: TrackWithMonitoringData;
  onDelete: () => void;
  onClose: () => void;
  onEditTitle: (value: string) => Promise<void>;
  onEditArtist: (value: string) => Promise<void>;
  showNext: boolean;
  onNext: () => void;
  showPrevious: boolean;
  onPrevious: () => void;
  subscriptionFormula?:string;
}

export function TrackPanel(props: Props) {
  const queryClient = useQueryClient();
  const { track, subscriptionFormula } = props;
  const broadcastCount = track.radio + track.tv + track.spotify + track.youtube + track.soundcloud;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isEditTitle, setIsEditTitle] = useState(false);
  const [titleValue, setTitleValue] = useState(track.title);
  const [isEditArtist, setIsEditArtist] = useState(false);
  const [artistValue, setArtistValue] = useState(track.artist);
  const cancelDeleteButtonRef = useRef(null);
  const onDelete = async () => {
    await api.deleteTrack(track.id);
    queryClient.refetchQueries({ queryKey: ['/api/user-info'], active: true });
    setShowDeleteModal(false);
    props.onDelete();
  };
  const handleOnClose = () => {
    setIsEditTitle(false);
    setIsEditArtist(false);
    props.onClose();
  };
  const handleOnNext = () => {
    setIsEditTitle(false);
    setIsEditArtist(false);
    props.onNext();
  };
  const handleOnPrevious = () => {
    setIsEditTitle(false);
    setIsEditArtist(false);
    props.onPrevious();
  };

  useEffect(() => {
    setIsEditTitle(false);
    setIsEditArtist(false);
    setArtistValue(track.artist);
    setTitleValue(track.title);
  }, [track]);

  return (
    <>
      <PageTitle title={`Détail du titre "${track.title}"`}/>
      <div
        id="panel"
        className="fixed flex flex-col top-0 right-0 bg-white shadow-2xl w-full md:w-112 h-full z-10 animate-fromRight p-6 pb-0 overflow-y-auto customScrollBar"
      >
        <div className="flex justify-between items-center mb-6">
          <h5 className="text-xl md:text-2xl m-0">Détail du titre</h5>
          {/*<div className="more mr-auto ml-2">*/}
          {/*  <FontAwesomeIcon*/}
          {/*    icon={duotone('ellipsis-vertical')}*/}
          {/*    className="hidden md:flex w-5 h-5 rounded-full bg-primary-100 p-2 hover:bg-primary-200"*/}
          {/*  />*/}
          {/*  <div className="dropdown md:w-full md:!mt-10 md:left-0 md:ml-[50%] md:-translate-x-1/2 flex flex-row">*/}
          {/*    <a*/}
          {/*      onClick={() => setShowDeleteModal(true)}*/}
          {/*      className="p-3 md:px-4 md:py-3 flex items-center text-red hover:text-red-800 md:hover:bg-red-50 md:border-t border-gray-200"*/}
          {/*    >*/}
          {/*      <FontAwesomeIcon icon={duotone('trash-can')} className="mr-3" />*/}
          {/*      <span className="hidden md:flex">Supprimer</span>*/}
          {/*    </a>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <button onClick={handleOnClose} className="button py-2 px-3 outline text-primary text-sm leading-none">
            <FontAwesomeIcon icon={duotone('xmark')} className="mr-2 h-4" />
            Fermer
          </button>
        </div>
        <div>
          <div className="track informations">
            <div
              className="title group flex items-baseline relative"
              onClick={() => {
                setIsEditArtist(false);
                setIsEditTitle(true);
              }}
            >
              <span>Titre&nbsp;:&nbsp;</span>
              {!isEditTitle && (
                <>
                  <strong className="break-anywhere">{track.title}</strong>
                  <Tippy content="Modifier le titre">
                    <button className="w-5 inline-flex opacity-0 transition-all group-hover:opacity-100 ml-3 flex-shrink-0 self-end mb-1">
                      <FontAwesomeIcon
                        icon={duotone('edit')}
                        className="text-primary hover:text-primary-400 cursor-pointer"
                      />
                    </button>
                  </Tippy>
                </>
              )}
              {isEditTitle && (
                <>
                  <input
                    autoFocus={true}
                    value={titleValue}
                    onChange={(event) => setTitleValue(event.target.value)}
                    className="!py-0.5 !px-1 text-md items-center relative !m-0 font-bold !pr-16 leading-none focus:!ring-2"
                    onKeyDown={async (event) => {
                      if (event.code === 'Enter' && titleValue && titleValue.trim().length > 0) {
                        await props.onEditTitle(titleValue);
                        setIsEditTitle(false);
                      } else if (event.code === 'Escape') {
                        setTitleValue(track.title);
                        setIsEditTitle(false);
                      }
                    }}
                  />
                  <Tippy content="Cliquez pour valider">
                    <button
                      onClick={async (event) => {
                        if (titleValue && titleValue.trim().length > 0) {
                          await props.onEditTitle(titleValue);
                          setIsEditTitle(false);
                        }
                      }}
                      className="absolute right-0.5 text-[11px] shadow border-2 border-white text-white bg-gray-800 p-1 rounded-md leading-none top-1/2 -translate-y-1/2"
                    >
                      <strong>⏎</strong>&nbsp;&nbsp;ou&nbsp;&nbsp;
                      <strong>esc</strong>
                    </button>
                  </Tippy>
                </>
              )}
            </div>
            <div
              className="artist text-gray-700 group flex items-baseline relative"
              onClick={() => {
                setIsEditTitle(false);
                setIsEditArtist(true);
              }}
            >
              <span>Artiste&nbsp;:&nbsp;</span>
              {!isEditArtist && (
                <>
                  <strong>{track.artist || <>&mdash;</>}</strong>
                  <Tippy content="Modifier l'artiste">
                    <button className="w-5 inline-flex opacity-0 transition-all group-hover:opacity-100 ml-3 flex-shrink-0 self-end mb-1">
                      <IconEdit className="text-primary w-4 hover:text-primary-400 cursor-pointer" />
                    </button>
                  </Tippy>
                </>
              )}
              {isEditArtist && (
                <>
                  <input
                    autoFocus={true}
                    value={artistValue}
                    onChange={(event) => setArtistValue(event.target.value)}
                    className="!py-0.5 !px-1 text-md items-center relative !m-0 font-bold !pr-16 leading-none focus:!ring-2"
                    onKeyDown={async (event) => {
                      if (event.code === 'Enter' && artistValue && artistValue.trim().length > 0) {
                        await props.onEditArtist(artistValue);
                        setIsEditArtist(false);
                      } else if (event.code === 'Escape') {
                        setArtistValue(track.artist);
                        setIsEditArtist(false);
                      }
                    }}
                  />
                  <Tippy content="Cliquez pour valider">
                    <button
                      onClick={async (event) => {
                        if (artistValue && artistValue.trim().length > 0) {
                          await props.onEditArtist(artistValue);
                          setIsEditArtist(false);
                        }
                      }}
                      className="absolute right-0.5 text-[11px] shadow border-2 border-white text-white bg-gray-800 p-1 rounded-md leading-none top-1/2 -translate-y-1/2"
                    >
                      <strong>⏎</strong>&nbsp;&nbsp;ou&nbsp;&nbsp;
                      <strong>esc</strong>
                    </button>
                  </Tippy>
                </>
              )}
            </div>
            <div className="duration text-gray-700">
              Durée :{' '}
              <strong>
                {track.duration ? (
                  moment.utc(moment.duration(track.duration, 'milliseconds').asMilliseconds()).format('mm:ss')
                ) : (
                  <>&mdash;</>
                )}
              </strong>
            </div>
            {track.release_date && (
              <div className="duration mt-4 text-xs text-gray-500">
                Date de sortie :&nbsp;
                <strong>{moment(track.release_date).format('DD/MM/YYYY')}</strong>
              </div>
            )}
            {track.isrc && (
              <div className="isrc text-xs text-gray-500">
                ISRC :&nbsp;
                <strong>{track.isrc}</strong>
              </div>
            )}
            {/*<div className="group flex items-baseline relative">*/}
            {/*  <div className="duration text-xs text-gray-500">Mon statut sur l'oeuvre : <strong>Co-compositeur</strong></div>*/}
            {/*  <Tippy content="Modifier mon statut">*/}
            {/*    <button className="w-5 inline-flex opacity-0 transition-all group-hover:opacity-100 ml-3 flex-shrink-0 self-end mb-1">*/}
            {/*      <IconEdit className="text-primary w-4 hover:text-primary-400 cursor-pointer" />*/}
            {/*    </button>*/}
            {/*  </Tippy>*/}
            {/*</div>*/}
            {/*<div className="p-3 text-xs mt-4 rounded bg-yellow-200">*/}
            {/*  <p>Nous avons détecté que vous n'êtes pas le seul ayant droit sur cette œuvre. Merci de préciser votre statut <strong>pour mieux calculer vos droits</strong>.</p>*/}
            {/*  <div className="flex items-center"><span>Je suis :&nbsp;</span>*/}
            {/*  <select id="select" className="bg-transparent underline text-primary p-0 cursor-pointer">*/}
            {/*    <option value="">-----------</option>*/}
            {/*    <option value="1">Co-auteur</option>*/}
            {/*    <option value="2">Co-truc</option>*/}
            {/*    <option value="3">Co-machin</option>*/}
            {/*    <option value="4">Co-bidule</option>*/}
            {/*    <option value="5">Co-rones</option>*/}
            {/*  </select>*/}
            {/*    <span>&nbsp;de l'oeuvre &mdash;</span>*/}
            {/*    <div className="button ml-1 py-1 px-2 rounded-md text-[12px]">Valider</div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
        <div>
          <hr />
          <h5>Diffusions</h5>
          {broadcastCount > 0 && (
            <div>
              <div className="text-sm">Total des diffusions</div>
              <strong className="text-3xl">{broadcastCount.toLocaleString()}</strong>
              <dl className="mt-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <Tippy content="Cumul des streams sur Spotify, YouTube et SoundCloud.">
                  <div className="relative cursor-help">
                    <dt>
                      <div className="absolute rounded-md bg-stream-100 p-3.5">
                        <FontAwesomeIcon icon={icon({name: 'cloud-music', family: 'duotone', style: 'solid'})} className="h-6 w-6 flex text-stream-800"/>
                      </div>
                      <p className="ml-16 truncate  text-gray-600">

                        <span>Streams</span>
                      </p>
                    </dt>
                    <dd className="ml-16 flex items-baseline">
                        <p className="text-xl font-bold text-gray-900">
                          {(track.spotify + track.soundcloud + track.youtube).toLocaleString('fr-FR', {
                            notation: 'compact',
                            compactDisplay: 'short',
                            maximumFractionDigits: 1,
                          })}
                        </p>
                    </dd>
                  </div>
                </Tippy>
                <div className="relative ">
                  <dt>
                    <div className="absolute rounded-md bg-radio-50 p-3.5">
                      <FontAwesomeIcon icon={icon({name: 'radio', family: 'duotone', style: 'solid'})} className="h-6 w-6 flex text-radio-500"/>
                    </div>
                    <p className="ml-16 truncate  text-gray-600">Radios</p>
                  </dt>
                  <dd className="ml-16 flex items-baseline">
                    {subscriptionFormula !== '1' && (
                      <p className="text-xl font-bold text-gray-900">
                        {track.radio.toLocaleString('fr-FR', {
                          notation: 'compact',
                          compactDisplay: 'short',
                          maximumFractionDigits: 1,
                        })}
                      </p>
                    )}
                    {subscriptionFormula === '1' && (
                      <a
                        href="/settings/subscription"
                        className="text-xs !text-orange hover:!text-orange-700 underline"
                      >
                        Changer de formule

                      </a>
                    )}
                  </dd>
                </div>
                <div className="relative ">
                  <dt>
                    <div className="absolute rounded-md bg-tv-50 p-3.5">
                      <FontAwesomeIcon icon={icon({name: 'tv-music', family: 'duotone', style: 'solid'})} className="h-6 w-6 flex text-tv-500"/>
                    </div>
                    <p className="ml-16 truncate  text-gray-600">TV</p>
                  </dt>
                  <dd className="ml-16 flex items-baseline">
                    {subscriptionFormula === '3' && (
                      <p className="text-xl font-bold text-gray-900">
                        {track.tv.toLocaleString('fr-FR', {
                          notation: 'compact',
                          compactDisplay: 'short',
                          maximumFractionDigits: 1,
                        })}
                      </p>
                    )}
                    {subscriptionFormula !== '3' && (
                      <a
                        href="/settings/subscription"
                        className="text-xs !text-orange hover:!text-orange-700 underline"
                      >
                        Changer de formule

                      </a>
                    )}
                  </dd>
                </div>
              </dl>
            </div>
          )}
        </div>
        <hr/>
        <div className="charts pb-6 space-y-6 divide-y divide-gray-200">
          <div><BroadcastChartWithFilter forceChannel="streams" trackId={track.id} customClass="h-48"/></div>
          <div className="pt-6"><BroadcastChartWithFilter forceChannel="radio" trackId={track.id} customClass="h-48"/></div>
          <div className="pt-6"><BroadcastChartWithFilter forceChannel="tv" trackId={track.id} customClass="h-48"/></div>
          {/* <BroadcastChart noData={broadcastCount === 0} /> */}
        </div>
        <div className="mt-auto flex space-x-2 md:space-x-0 justify-between items-end sticky bottom-0 p-6 -mx-6 bg-white shadow border-t">
          {props.showPrevious && (
            <a
              className="button outline px-4 text-primary text-xs md:text-sm hover:text-primary-900 select-none"
              onClick={handleOnPrevious}
            >
              <FontAwesomeIcon icon={duotone('arrow-left')} className="h-5"/>
            </a>
          )}
          {props.showNext && (
            <a
              className="button outline px-4 text-primary text-xs md:text-sm hover:text-primary-900 select-none ml-auto"
              onClick={handleOnNext}
            >
              <FontAwesomeIcon icon={duotone('arrow-right')} className="h-5"/>
            </a>
          )}
        </div>
      </div>
      <Modal isOpen={showDeleteModal} setOpen={setShowDeleteModal} initialFocus={cancelDeleteButtonRef}>
        <>
          <div className="bg-white px-4 py-4 sm:p-6">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <IconDelete className="text-red"/>
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <Dialog.Title as="h3" className="text-xl leading-loose">
                  Supprimer le titre ?
                </Dialog.Title>
                <div className="mt-6">
                  <p>La suppression du titre entrainera l'arrêt de la récolte des statistiques.</p>
                  <p>
                    Êtes-vous sur de vouloir supprimer le titre&nbsp;
                    <strong>{track.title}</strong> ?
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-100 px-6 py-4 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded border border-transparent px-4 py-2 mb-4 sm:mb-0 bg-transparent text-primary hover:bg-primary-100 focus:ring-opacity-30 focus:outline-none focus:ring-4 focus:ring-primary sm:ml-3 sm:w-auto"
              onClick={() => setShowDeleteModal(false)}
              ref={cancelDeleteButtonRef}
            >
              Annuler
            </button>
            <button
              type="button"
              className="w-full inline-flex justify-center rounded border border-transparent px-4 py-2 bg-red text-white hover:bg-red-700 focus:ring-opacity-30 focus:outline-none focus:ring-4 focus:ring-red-500 sm:ml-3 sm:w-auto"
              onClick={() => onDelete()}
            >
              Supprimer le titre
            </button>
          </div>
        </>
      </Modal>
    </>
  );
}
