import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import classNames from 'classnames';

type EmptyStateProps = {
  color?: 'gray' | 'green' | 'red' | 'blue' | 'yellow' | 'orange';
  title?: string;
  shadow?: 'shadow' | 'shadow-xl' | 'shadow-none';
  children?: React.ReactNode;
  className?: string;
  hideIcon?: boolean; // Nouvelle propriété pour masquer l'icône
};

const EmptyState: React.FC<EmptyStateProps> = ({
                                                 color,
                                                 title,
                                                 shadow = 'shadow',
                                                 children,
                                                 className,
                                                 hideIcon = false, // Valeur par défaut : false
                                               }) => {
  let bgColor, textColor;

  switch (color) {
    case 'gray':
      bgColor = 'bg-gray-100';
      textColor = 'text-gray-800';
      break;
    case 'green':
      bgColor = 'bg-green-100';
      textColor = 'text-green-800';
      break;
    case 'red':
      bgColor = 'bg-red-100';
      textColor = 'text-red-800';
      break;
    case 'blue':
      bgColor = 'bg-blue-100';
      textColor = 'text-blue-800';
      break;
    case 'yellow':
      bgColor = 'bg-yellow-100';
      textColor = 'text-yellow-900';
      break;
    case 'orange':
      bgColor = 'bg-orange-100';
      textColor = 'text-orange-800';
      break;
    default:
      bgColor = 'bg-white';
      textColor = 'text-gray-800';
  }

  return (
    <div
      className={`mx-auto flex w-full flex-col items-center justify-center space-y-6 rounded p-6 text-center ${textColor} ${bgColor} ${shadow} ${className ? className : ''}`}
    >
      {!hideIcon && ( // Condition pour afficher ou masquer l'icône
        <FontAwesomeIcon
          icon={icon({ name: 'bullhorn', family: 'sharp', style: 'light' })}
          className={`h-16 ${textColor}`}
        />
      )}
      {title && <h4 className="mb-0">{title}</h4>}
      {children}
    </div>
  );
};

export default EmptyState;
